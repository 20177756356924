export default {
     homeheader: "Origins connected",
     homeintroduccion: "Naples Prime is a 100% Colombian International Trading Company whom, through accompaniment and advice to its clients and suppliers, seeks to satisfy the latent needs of the local and global market, encouraging reciprocal trade between strategic allies, and supporting social development locally.",
     headabout: "About us",
     galeriah1: "OUR PURPOSE IS",
     galeriat2: "MAKING TECHNOLOGY  THE CENTER OF RESPONSIBLE,  HUMAN CENTRIC, GLOBAL SUPPLY CHAINS",
     galeriat3: "We are committed to the progress of our stakeholders, from the beginning adopting a business philosophy founded on Social Responsibility as our vision, based on ethics, care for the environment and sustainability. We want to contribute to the social development of vulnerable communities and we demonstrate this through the creation of projects along with government support, creating jobs and expanding our offer of products.",
     aboutvalues: "ETHICS • LEADERSHIP • RESPONSIBILITY • COMMITMENT • QUALITY • INNOVATION",
     aboutparrafo: "Through leadership and ethics in its work, Naples Prime demonstrates its commitment to work performed responsibly and with integrity, always prioritizing the highest quality standards and incorporating the latest innovations in its business processes.",
     aboutparrafo2: " Likewise, working towards the development of sustainable methods that generate added value to all links in the chain, it resorts to the use of the latest technologies in the supply and distribution systems of raw and processed products, which not only guarantee the conservation of the planet, but the optimization of resources, generating in turn a circular life cycle that pays off at all associated levels.",
     abouthead: " ORIGINS CONNECTED",
     abouthead2: "OUR MISSION",
     aboutmission: "Be the mediating bridge that streamlines the execution of businesses between national and international corporations, local minorities and actors of different nationalities, whose origins limit their ability to explore, enter and interconnect with other markets. We want to help facilitate and ensure their commercial processes to be executed and generate value through sustainability, leaving a social footprint behind and offering optimal solutions tailored to the intrinsic needs of the different communities involved, allowing the expansion of our capacities and thus contributing to the social and economic development of our country.",
     abouthead3: "OUR VISION",
     aboutvision: " We will be active leaders offering a highly diversified portfolio, made up of carefully selected national and international products which compete globally for their quality and exceptionality, thus contributing to the strengthening of minorities and vulnerable local communities that seek a stable and sustainable livelihood over time, highlighting our commitment to work through the consolidation of efficient production processes under the highest quality standards generating confidence and security to our current and future allies.",
     abouthead4: "OUR HISTORY",
     history1: "Naples Prime was created in September 2015 in Bogotá, Colombia, as a project with which its founder based on his experience and trajectory in international business, wanted to establish a sustainable company that satisfies the need of small producers and national manufacturers to market and position their products abroad, likewise, supply local demand with high-quality products from strategic allies abroad.",
     history2: "In September 2017, Naples Prime was formally constituted as a company, beginning its commercial operations and building strong relationships with its customers and suppliers, whom have been its support achieving comprehensive growth over time, which has not only allowed it to be profitable, but also to obtain the credibility that the sector requires. This has made it possible for Naples Prime to support regional Colombian authorities in economic development projects for communities located in remote areas of the Colombian territory, generating a sense of belonging and solidification of the growing brand.",
     history3: "Advancing in the steps that its economic activity required, in October 2019 the Ministry of Commerce, Industry and Tourism - MINCIT, granted Naples Prime SAS CI the resolution that authorizes it as an International Trading Company. Today, Naples Prime is a consolidated company with knowledge about its business, it has several branches in the main cities of the country and a team with the skills demanded by the global market in different areas of international trade. In addition to the aforementioned, it continues to strengthen and build alliances with key national and international partners through the development of strategic objectives that point in the direction of the scope of the Vision that has been formulated."

}