import React, { Component } from 'react';
import altacalidad from "../images/Alta calidad@3x.png";
import altovalor from "../images/Alto valor@3x.png";
import ventas from "../images/Crecimiento en ventas@3x.png";
import durabilidad from "../images/Durabilidad@3x.png";
import empaquereciclable from "../images/Empaque reciclable@3x.png";
import exclusivo from "../images/Exclusivo@3x.png";
import global from "../images/Global@3x.png";
import nuevo from "../images/Nuevo@3x.png";
import reciclable from "../images/Reciclable@3x.png";
import rse from "../images/RSE@3x.png";
import green from "../images/Think green@3x.png";
import familia from "../images/Orientado a la familia@3x.png";



class Featurebrand extends Component {
    render() {
        let feat =this.props.featurees;
        if(this.props.featurees.charAt(0)==" "){
            feat = feat.substring(1);
        }

        
        let imagen = <div></div>;
        if(feat == "Alta Calidad"){
            imagen = <img className="featurebrand" src={altacalidad}></img>
        }
        else if(feat == "Alto Valor"){
            imagen = <img className="featurebrand" src={altovalor}></img>

        }
        else if(feat == "Responsabilidad Social Empresarial"){
            imagen = <img className="featurebrand" src={rse}></img>

        }else if(feat == "Reciclable"){
            imagen = <img className="featurebrand" src={reciclable}></img>

        }else if(feat == "Empaque Reciclable"){
            imagen = <img className="featurebrand" src={empaquereciclable}></img>

        }else if(feat == "Think Green/Conciencia Ambiental"){
            imagen = <img className="featurebrand" src={green}></img>

        }else if(feat == "Internacional/Global"){
            imagen = <img className="featurebrand" src={global}></img>

        }else if(feat == "Orientado en la Familia"){
            imagen = <img className="featurebrand" src={familia}></img>

        }else if(feat == "Crecimiento en ventas"){
            imagen = <img className="featurebrand"  src={ventas}></img>

        }else if(feat == "Nuevo"){
            imagen = <img className="featurebrand" src={nuevo}></img>

        }
        else if(feat == "Exclusivo"){
            imagen = <img className="featurebrand" src={exclusivo}></img>

        }
        else if(feat == "Durabilidad"){
            imagen = <img className="featurebrand" src={durabilidad}></img>

        }
        return (
            <div className="divfeaturebrand" style={{paddingTop: "0.5vw", paddingBottom: "0.5vw"}}>
                <div className="row bot">
                    <div className="col-4">
                    {imagen}
                    </div>
                    <div className="col-8 feat">
                        <p className="pfeat">
                        {this.props.feature}
                        </p>
                    
                    </div>
                </div>
            </div>
        );
    }
}

export default Featurebrand;