import React, { Component } from 'react';
import '../css/partners.css';
import Allie from './Allie.js';
import Brand from './Brand.js';
import fire from '../fire.js';

class Partners extends Component {
    state = {
        nombres: [],
        links: [],
        logos: [],
        countries: [],
        countrien: [],
        paiseses: [],
        paisesen: []
    }
    componentDidMount = () => {
        let nombres = [];
        let links = [];
        let logos = [];
        let paiseses = [];
        let paisesen = [];
        let paisesnd = [];
        fire.database().ref('imports/').once('value', (snapshot) => {
            snapshot.forEach(
                function (ChildSnapshot) {
                    let cat = ChildSnapshot.val();
                    let llaves = Object.keys(cat)
                    llaves.forEach(e => {
                        if (!(cat[e].nombre === "KOZIOL" && nombres.includes("KOZIOL"))) {
                            nombres.push(cat[e].nombre);
                            links.push(cat[e].detalle.link);
                            logos.push(cat[e].imagen);
                            paisesen.push(cat[e].paisen);
                            paiseses.push(cat[e].paises);
                            paisesnd.push(cat[e].paisnd);
                       
                        }

                    });

                }
            );
        
            let countries = [];
            let countrien = [];
            let countrind = [];
            for (let i = 0; i < paiseses.length; i++) {
                if (!countries.includes(paiseses[i])) {
                    countries.push(paiseses[i]);
                }
                if (!countrien.includes(paisesen[i])) {
                    countrien.push(paisesen[i]);
                }
                if (!countrind.includes(paisesnd[i])) {
                    countrind.push(paisesnd[i]);
                }
            }
            this.setState({ nombres: nombres, links: links, logos: logos, countrien, countries,countrind, paisesen, paiseses, paisesnd });
        })
    }
    render() {
        let brands = "";
        let ally = '';
        let dif = '';
        let WestCoastWholesale = '';
        let LongHornBrands = '';
        let DutchConvenienceMeat = '';
        let Woojeon = '';
        let primares = '';
        let calth = '';
        let beyond = '';
        let wells = '';
        let yu = '';
        let divbrands =
            <div className="col-10" style={{ textAlign: "right" }}>
                {this.state.countries.map((pais, i) => <div>
                    <h1 className="paispartner">{pais}</h1>

                    {this.state.nombres.map((e, i) => this.state.paiseses[i] === pais ? <Brand key={i} lang={this.props.lang} nombre={e} link={this.state.links[i]} logo={this.state.logos[i]}></Brand> : "")}
                </div>)}
            </div>;
        if (this.props.lang === "es") {
            brands = "MARCAS EN NUESTRO PORTFOLIO";
            ally = "ALIADOS NACIONALES E INTERNACIONALES";
            dif = "NUESTROS ALIADOS NOS AYUDAN A HACER UNA DIFERENCIA";
            WestCoastWholesale = "West Coast Wholesale es una empresa comercial en el suministro y distribución global de productos de marca. Mayorista especializado en la distribución de una amplia variedad de bienes de consumo de marca de rápido movimiento (FMCG). A lo largo de los años, ha construido una sólida reputación y hemos desarrollado relaciones a largo plazo con nuestros proveedores, fabricantes y clientes en un amplio portafolio de marcas dentro de los sectores de productos deportivos, alimenticios, cuidado personal y cuidado del hogar.";
            LongHornBrands = "Más que tiendas online, más que productos... Julia’s moments representa una familia de poderosas marcas que inspiran, empoderan y complacen. Comprometidos con construir y representar a una familia de las mejores marcas minoristas de moda íntima del mundo que ofrecen experiencias cautivadoras e invitan a los clientes a un mundo completamente nuevo de belleza y estilo. “Nosotros no solo vendemos productos... nosotros vendemos experiencias y creamos momentos. Nosotros somos Julia’s moments. Somos líder del segmento. Con visión en el futuro. Enfocado en la gente. responsables. influyentes";
            DutchConvenienceMeat = "Dutch Convenience Meat suministra sus productos personalizados y de calidad a varias cadenas de restaurantes, mayoristas y centros de distribución. Bajo pedido, entrega incluso bajo etiqueta privada para cadenas de supermercados. Dutch Convenience Meat elige extremadamente fresco. Por lo tanto, no dispone de existencias, entregando en 3 semanas cualquier pedido de cantidad mínima y máxima bajo pedido, sirviendo a sus clientes de manera óptima. Al hacerlo, personaliza su entrega en función de su equipo de cocina disponible. ";
            Woojeon = "Ubicada en Geumcheon-gu, Seúl, Corea del Sur, WOOJEON CO., LTD ofrece soluciones totales para piezas de teléfonos móviles para grandes fabricantes de teléfonos, integración vertical para el diseño y fabricación de herramientas de inyección, moldeo, etc. También es líder en tecnologías avanzadas para LSM y LSR. Además de esto, la importación de metales preciosos, minerales, fundición y refinación también es un subnegocio.";
            primares = "PRIMARES S.A.S genera contenidos creativos desde la ciudad de Medellín en Colombia. Su premisa es PRIMAR, y con tal fin, desarrolla contenidos audiovisuales desde su etapa creativa para su difusión trasmediatica obedeciendo a diferentes métricas e intenciones de lanzamiento, ya sean corto, medio y largometrajes con formatos corporativos y documentales.";
            calth = "Laboratorio coreano de desarrollo de pruebas para la detección en sitio de las primeras etapas de enfermedades infecciosas causadas por virus y bacterias, ofreciendo soluciones tecnológicas de alta sensibilidad para el diagnóstico temprano de infecciones en plataformas optimizadas o kits de diagnóstico rápido para antígenos o anticuerpos a través de muestras de sangre o saliva. ";
            beyond = "Establecida en 2009, Hunan Beyond Medical Technology Co., Ltd, con sede en el distrito de Yuelu, Changsha, con un capital de registro de 30,48 millones de RMB, se dedica a I + D, fabricación, marketing y servicio de soluciones de productos en la industria de la atención médica. gestión de infusiones (bomba de infusión, bomba de jeringa, etc.), soluciones de apnea del sueño (CPAP, dispositivos BPAP y máscaras), equipos dentales, ingeniería médica, sistema de llamada a enfermeras, etc. Homologado CE para todos los productos. y se lanzará una gran calidad a los hospitales de todos los niveles dentro y fuera de China, mientras se brindan servicios seguros y convenientes para los pacientes en todo el mundo. Beyond se compromete a convertirse en el proveedor líder de productos y soluciones médicos.";
            wells = "WELLS BIO desarrolla y fabrica pruebas de diagnóstico in vitro basadas en inmunoquímicos, bioquímicos, y tecnología de diagnóstico molecular para el diagnóstico precoz de enfermedades. En esta época, donde La importancia del diagnóstico y tratamiento precoces ha ido en aumento debido al envejecimiento de la población y globalización de las enfermedades, WELLS BIO busca ser el compañero de vida de toda la humanidad en el mundo.";
            yu = "Productos importados desde China.";
        } else if (this.props.lang === "en") {
            brands = "BRANDS IN OUR PORTFOLIO";
            ally = " NATIONAL AND INTERNATIONALS ALLIES";
            dif = "OUR ALLIES HELP US MAKE A DIFFERENCE";
            WestCoastWholesale = "West Coast Wholesale is a trading company in the global supply and distribution of branded goods. Wholesaler specialising in the distribution of a wide variety of branded fast moving consumer goods (FMCG). Throughout the years, have built a strong reputation and developed long term relationships whit suppliers, manufacturers and clients in a wide portfolio of brands within the sectors of Sports, Food, Personal Care & Home Care products.";
            LongHornBrands = "More than online stores, more than products ... Julia’s moments represents a family of powerful brands that inspire, empower and please. Committed to building and representing a family of the world's best intimate fashion retail brands that deliver captivating experiences and invite customers to a whole new world of beauty and style. “We don't just sell products ... we sell experiences and create moments. We are Julia’s moments. We are the leader of the segment. With vision in the future. Focused on people. responsible. influencer.";
            DutchConvenienceMeat = " Dutch Convenience Meat supplies its customization and quality products to various restaurant chains, wholesalers and distribution centers. On request, Dutch Convenience Meat delivers even under private label for supermarket chains. Dutch Convenience Meat chooses for extremely fresh, therefore don't have stocks, delivering within 3 weeks any minimum- and maximum quantity orders on request. Serving their customers optimally. In doing so, Dutch Convenience Meat customize its delivery depending on your available kitchen equipment. ";
            Woojeon = "Located in Geumcheon-gu, Seoul, Korea, WOOJEON CO., LTD offers total Solutions for Mobile Phone Parts for big phone manufacturers, Vertical Integration for Injection Tool Design & Fabrication, Molding, etc. Also is a leader in Advanced Technologies for LSM and LSR. In addition to this, importing precious metals, minerals, smelting, and refining is sub-business as well.";
            primares = "PRIMARES S.A.S generates creative content from the city of Medellín in Colombia. Its premise is PRIMAR and to this end, it develops audiovisual content from its creative stage for transmedia broadcasting, obeying different metrics and launch intentions, whether short, medium and feature films with corporate and documentary formats.";
            calth = "Korean laboratory for the development of tests for the on-site detection of the first stages of infectious diseases caused by viruses and bacteria, offering high-sensitivity technological solutions for the early diagnosis of infections on optimized platforms or rapid diagnostic kits for antigens or antibodies through of blood or saliva samples.";
            beyond = "Established in 2009, Hunan Beyond Medical Technology Co., Ltd based in Yuelu District, Changsha with the registration capital of 30.48 million RMB, is dedicated to R&D, manufacturing, marketing and service of product solutions in medical healthcare industry.The main business service involves infusion management (Infusion pump, syringe pump, etc.), sleep apnea solutions (CPAP, BPAP devices and masks), dental equipment, medical engineering, nurse call system, etc. CE approved for all the products..Products with high-performance and great quality are to be launched to hospitals at all levels in and outside China, whilst providing safe and convenient services for patients in the world. Beyond is committed to become the leading supplier of medical products and solutions.";
            wells = "WELLS BIO develops and manufactures in vitro diagnostic tests based on immunochemical, biochemical, and molecular diagnostic technology for early diagnosis of diseases. In this day and age, where  the importance of early diagnosis and treatment have been on the rise due to the aging population and globalization of illnesses, WELLS BIO pursues to be the world’s life-caring companion to all mankind.";
            yu = "Imported products from China.";
            divbrands =
            <div className="col-10" style={{ textAlign: "right" }}>
                {this.state.countries.map((pais, i) => <div>
                    <h1 className="paispartner">{this.state.countrien[i]}</h1>

                    {this.state.nombres.map((e, i) => this.state.paiseses[i] === pais ? <Brand key={i} lang={this.props.lang} nombre={e} link={this.state.links[i]} logo={this.state.logos[i]}></Brand> : "")}
                </div>)}
            </div>;

        }
        else if (this.props.lang === "nd") {
            brands = "MERKEN IN ONS PORTFOLIO";
            ally = "NATIONALE EN INTERNATIONALE BONDGENOTEN";
            dif = "ONZE BONDGENOTEN HELPEN ONS HET VERSCHIL TE MAKEN";
            WestCoastWholesale = "West Coast Wholesale is een handelsonderneming in de wereldwijde levering en distributie van merkartikelen. Groothandelaar gespecialiseerd in de distributie van een breed scala aan snel bewegende consumptiegoederen van het merk (FMCG). Door de jaren heen een sterke reputatie hebben opgebouwd en langdurige relaties opgebouwd met leveranciers, fabrikanten en klanten in een brede portfolio van merken binnen de sectoren Sport, Voeding, Persoonlijke Verzorging & Huishoudelijke Producten";
            LongHornBrands = "Meer dan online winkels, meer dan producten ... Julia’s momenten vertegenwoordigen een familie van krachtige merken die inspireren, versterken en behagen. Toegewijd aan het opbouwen en vertegenwoordigen van een familie van 's werelds beste intieme modewinkelmerken die boeiende ervaringen bieden en klanten uitnodigen in een geheel nieuwe wereld van schoonheid en stijl. “We verkopen niet alleen producten ... we verkopen ervaringen en creëren momenten. Wij zijn Julia’s momenten. Wij zijn de leider van het segment. Met visie in de toekomst. Gericht op mensen. verantwoordelijk. influencer.";
            DutchConvenienceMeat = " Dutch Convenience Meat levert haar maatwerk en kwaliteitsproducten aan diverse restaurantketens, groothandels en distributiecentra. Op verzoek levert Dutch Convenience Meat zelfs onder private label aan supermarktketens. Dutch Convenience Meat kiest voor extreem vers, heeft daarom geen voorraden en levert op aanvraag binnen 3 weken eventuele minimum- en maximumhoeveelheden. Hun klanten optimaal bedienen. Daarbij past Dutch Convenience Meat de bezorging aan op basis van uw beschikbare keukenapparatuur. ";
            Woojeon = "WOOJEON CO., LTD, gevestigd in Geumcheon-gu, Seoul, Korea, biedt totaaloplossingen voor mobiele telefoononderdelen voor grote telefoonfabrikanten, verticale integratie voor ontwerp en fabricage van spuitgereedschap, spuitgieten, enz. Is ook een leider in geavanceerde technologieën voor LSM en LSR. Daarnaast is het importeren van edele metalen, mineralen, smelten en raffinage ook een subactiviteit.";
            primares = 'PRIMARES S.A.S genereert creatieve content uit de stad Medellín in Colombia. Het uitgangspunt is PRIMAR en daartoe ontwikkelt het audiovisuele inhoud vanaf het creatieve podium voor transmedia-uitzendingen, waarbij verschillende statistieken en lanceringsintenties worden nageleefd, of het nu gaat om korte, middelgrote en speelfilms met bedrijfs- en documentaire formaten.';
            calth = 'Koreaans laboratorium voor de ontwikkeling van tests voor de detectie ter plaatse van de eerste stadia van infectieziekten veroorzaakt door virussen en bacteriën, met hooggevoelige technologische oplossingen voor de vroege diagnose van infecties op geoptimaliseerde platforms of snelle diagnosekits voor antigenen of antilichamen via van bloed- of speekselmonsters.';
            beyond = 'Opgericht in 2009, Hunan Beyond Medical Technology Co, Ltd gevestigd in Yuelu District, Changsha met het registratiekapitaal van 30,48 miljoen RMB, is gewijd aan R & D, productie, marketing en service van productoplossingen in de medische gezondheidszorg industrie. De belangrijkste zakelijke dienst omvat infusiebeheer (infusiepomp, spuitpomp, enz.), slaapapneuoplossingen (CPAP, BPAP-apparaten en maskers), tandheelkundige apparatuur, medische engineering, oproepsysteem voor verpleegkundigen, enz. CE ';
            wells = "WELLS BIO ontwikkelt en produceert in vitro diagnostische tests op basis van immunochemische, biochemische en moleculaire diagnostische technologie voor vroege diagnose van ziekten. In deze tijd, waar het belang van vroege diagnose en behandeling is toegenomen als gevolg van de vergrijzing van de bevolking en de globalisering van ziekten, streeft WELLS BIO na om 's werelds levensverlengende metgezel voor de hele mensheid te zijn.";
            yu = 'Geïmporteerde producten uit China.';
            divbrands =
            <div className="col-10" style={{ textAlign: "right" }}>
            {this.state.countries.map((pais, i) => <div>
                <h1 className="paispartner">{this.state.countrind[i]}</h1>

                {this.state.nombres.map((e, i) => this.state.paiseses[i] === pais ? <Brand key={i} lang={this.props.lang} nombre={e} link={this.state.links[i]} logo={this.state.logos[i]}></Brand> : "")}
            </div>)}
        </div>;
        }

        return (
            <div className="divpartners" id="divpartners">
                <h1 className="partnertit">
                    {dif}

                </h1>
                <div className="row bot" style={{ marginLeft: "2vw" }}>
                    <div className="col-lg-5 col-sm-12 scrollallie">
                        <div className="row">
                            <div className="col-1 bannerdiv">
                                <p className="bannertexto">
                                    {ally}
                                </p>
                                <p className="bannertexto">
                                    {ally}
                                </p>
                                <p className="bannertexto">
                                    {ally}
                                </p>
                                <p className="bannertexto">
                                    {ally}
                                </p>

                            </div>
                            <div className="col-10">


                                <Allie lang={this.props.lang} nombre={"WEST COAST WHOLESALE"} text={WestCoastWholesale} link={"https://westcoastwholesale.net/"} >
                                </Allie>
                                
                                <Allie lang={this.props.lang} nombre={"LONGHORN BRANDS"} text={LongHornBrands} link={"https://juliasmoments.com/"} >

                                </Allie>                                
                                <Allie lang={this.props.lang} nombre={"DUTCH CONVENIENCE MEAT"} text={DutchConvenienceMeat} link={"https://www.dutchconveniencemeat.nl/"} >

                                </Allie>                                
                                <Allie lang={this.props.lang} nombre={"WOOJEON CO"} text={Woojeon} link={"https://www.woojeon.co.kr/"} >

                                </Allie>                                
                                <Allie lang={this.props.lang} nombre={"PRIMARES"} text={primares} link={"https://www.primarespro.tv/"} >

                                </Allie>
                                <Allie lang={this.props.lang} nombre={"BEYOND MEDICAL"} text={beyond} link={"https://www.hnbyond.com/"} >

                                </Allie>
                                <Allie lang={this.props.lang} nombre={"WELLS BIO "} text={wells} link={"http://www.wellsbio.net/"} >

                                </Allie >
                                <Allie lang={this.props.lang} nombre={"CALTH"} text={calth} link={"http://www.thecalth.com/"} >

                                </Allie>
                                <Allie lang={this.props.lang} nombre={"YUTECH"} text={yu} link={""} >

                                </Allie>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-sm-12 scrollbrand">
                        <div className="row">
                            <div className="col-1 bannerdiv">
                                <p className="bannertexto">
                                    {brands}
                                </p>
                                <p className="bannertexto">
                                    {brands}
                                </p>
                                <p className="bannertexto">
                                    {brands}
                                </p>

                                <p className="bannertexto">
                                    {brands}
                                </p>

                                <p className="bannertexto">
                                    {brands}
                                </p>
                                <p className="bannertexto">
                                    {brands}
                                </p>

                                <p className="bannertexto">
                                    {brands}
                                </p>

                            </div>
                            {divbrands}

                        </div>
                    </div>
                    <div className="col-2">

                    </div>
                </div>
            </div>
        );
    }
}

export default Partners;
