import React, { Component } from 'react';
import Galeria from './Galeria.js';
import Home from './Home.js';
import '../css/header.css';
import Header from './Header.js';
import Portafolio from './Portafolio.js';
import Menu from './Menu.js';
import Footer from './Footer.js';
import AboutUs from './Aboutus.js';
import ContactUs from './ContactUs.js';
import Partners from './Partners.js';

class Contenedor extends Component {
    constructor(props) {
        super(props);
        this.state = { color: "white", menu: false, lang: "es" };

    }


    handlerMenu = () => {
        this.setState((prevState) => {
            return { menu: !prevState.menu };
        });
    };
    handleLang = (l) => {
        this.setState({ lang: l });
       
    }
    render() {

        return (
            <div style={{ height: "100%" }}>
                <Menu show={this.state.menu} click={this.handlerMenu} handleLang={this.handleLang}></Menu>

                <Home click={this.handlerMenu} handleLang={this.handleLang}></Home>

                <Header position={this.state.position} click={this.handlerMenu} lang={this.state.lang}  handleLang={this.handleLang}></Header>
                <Galeria  lang={this.state.lang}></Galeria>
                <Portafolio  lang={this.state.lang}></Portafolio>
                <AboutUs  lang={this.state.lang}></AboutUs>
                <Partners  lang={this.state.lang}></Partners>
                <ContactUs  lang={this.state.lang}></ContactUs>
                <Footer  lang={this.state.lang}></Footer>
            </div>
        );
    }
}

export default Contenedor;