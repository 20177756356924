import React, { Component } from 'react';
import Header from './Header.js';
import Categoria from './Categoria.js';
import Footer from './Footer.js';
import Menu from './Menu.js';
import '../css/productos.css';
import $ from 'jquery';
class Productos extends Component {
    state = {
        categoriasen: ["MEDICAL AND HEALTH",
            "FOOD",
            "HOME",
            "MOBILITY"
        ]
        ,
        categoriases: ["SALUD",
            "ALIMENTOS",
            "HOGAR",
            "MOVILIDAD"
        ]
        ,
        categoriasnd: ["GEZONDHEID",
            "ETEN",
            "HUIS",
            "MOBILITEIT"
        ]
        ,
        paiseses: [
            ["COREA DEL SUR", "ALEMANIA", "CHINA"],
            ["ESPAÑA", "HOLANDA", "INGLATERRA"],
            ["INGLATERRA", "ALEMANIA"],
            ["HOLANDA"]

        ],
        paisesen: [
            ["SOUTH KOREA", "GERMANY", "CHINA"],
            ["SPAIN", "NETHERLANDS", "ENGLAND"],
            ["ENGLAND", "GERMANY"],
            ["NETHERLANDS"]

        ],
        paisesnd: [
            ["ZUID-KOREA", "DUITSLAND", "CHINA"],
            ["SPANJE", "HOLLAND", "ENGELAND"],
            ["ENGELAND", "DUITSLAND"],
            ["HOLLAND"]

        ],
        menu: false,
        medical: {},
        lang: this.props.location.lang ? this.props.location.lang : "es"

    }
    handlerMenu = () => {
        this.setState((prevState) => {
            return { menu: !prevState.menu };
        });
    };
    

    componentDidMount = () => {
        window.scrollTo(0, 0);
        $(function () {
            $('[data-toggle="tooltip"]').tooltip()
        });

    }


    handleLang = (l) => {
        this.setState({ lang: l });

    }

    render() {
        let cats = this.state.categoriases;
        let listapaises = [];
        if (this.state.lang === "es") {

            listapaises = this.state.paiseses;
        } else if (this.state.lang === "en") {
            cats = this.state.categoriasen;
            listapaises = this.state.paisesen;


        }
        else if (this.state.lang === "nd") {
            cats = this.state.categoriasnd;
            listapaises = this.state.paisesnd;

        }


        return (
            <div id="divproductos" >
                <Menu show={this.state.menu} click={this.handlerMenu} handleLang={this.handleLang}></Menu>

                <Header click={this.handlerMenu} lang={this.state.lang} handleLang={this.handleLang}></Header>
                {cats.map((c, index) =>
                    <Categoria key={c.toString()} titulo={c} paises={listapaises[index]} lang={this.state.lang} nombre={this.state.categoriasen[index]} />)}
                <div className="divvacio">

                </div>
                <Footer lang={this.state.lang}  ></Footer>

            </div>

        );
    }
}

export default Productos;