import React, { Component } from 'react';
import Header from './Header.js';
import Footer from './Footer.js';
import vino from '../images/imagen-luna.jpg';
import Menu from './Menu.js';
import Feature from "./Featurebrand.js";
import '../css/detailbrand.css';
import Producto from './Producto.js';
import fire from '../fire.js';

class DetailBrand extends Component {
    state = {
        menu: false,
        lang: this.props.location.lang
    }
    handlerMenu = () => {
        this.setState((prevState) => {
            return { menu: !prevState.menu };
        });
    };
    handleLang = (l) => {
        this.setState({ lang: l });

    }
    componentDidMount = () => {
        
        let url = 'imports/' + this.props.location.categoria + '/' + this.props.match.params.id + '/detalle';
        this.setState({ lang: this.props.location.lang })
        fire.database().ref(url).once('value', (snap) => {

            this.setState({ detalle: snap.val() })
            console.log(snap.val());

        });

    }

    render() {
        let bread = "";
        let features = [];
        let descripcion = "";
        let pais = "";
        let link = "";
        let productos = [];
        let imagen1 = "";
        let imagen2 = "";
        if (this.state.lang == "es" && this.state.detalle) {
            bread = this.state.detalle.breadcrumbes;
            features = this.state.detalle.featureses.split(',');
            pais = this.state.detalle.paises;
            descripcion = this.state.detalle.descripciones;
            link = this.state.detalle.link;
            productos = this.state.detalle.productos;
            imagen1 = <img className="imagendetail" src={this.state.detalle.imagen1}></img>
            imagen2 = <img className="imagendetail" src={this.state.detalle.imagen2}></img>

        }
        else if (this.state.lang == "en" && this.state.detalle) {
            bread = this.state.detalle.breadcrumben;
            features = this.state.detalle.featuresen.split(',');
            pais = this.state.detalle.paisen;
            descripcion = this.state.detalle.descripcionen;
            link = this.state.detalle.link;
            productos = this.state.detalle.productos;
            

            imagen1 = <img className="imagendetail" src={this.state.detalle.imagen1}></img>
            imagen2 = <img className="imagendetail" src={this.state.detalle.imagen2}></img>

        }
        else if (this.state.lang == "nd" && this.state.detalle) {
            bread = this.state.detalle.breadcrumbnd;
            features = this.state.detalle.featuresnd.split(',');
            pais = this.state.detalle.paisnd;
            descripcion = this.state.detalle.descripcionnd;
            link = this.state.detalle.link;
            productos = this.state.detalle.productos;
            

            imagen1 = <img className="imagendetail" src={this.state.detalle.imagen1}></img>
            imagen2 = <img className="imagendetail" src={this.state.detalle.imagen2}></img>

        }
        return (
            <div className="divdetail">
                <Menu show={this.state.menu} click={this.handlerMenu}></Menu>

                <Header click={this.handlerMenu} lang={this.state.lang} handleLang={this.handleLang}></Header>

                <div id="detailbrand" style={{ height: "100%" }}>

                    <h6 className="bread">{bread}</h6>
                    <div className="row bot">
                        <div className="col-lg-8">
                            <p className="descripcionbrand">
                                {descripcion}
                            </p>
                        </div>
                        <div className="col-lg-2">

                            {features.map((e, i) => <Feature feature={e} featurees={this.state.detalle.featureses.split(',')[i]}> </Feature>)}





                        </div>
                        <div className="col-lg-2">
                            <h3>{pais}</h3>

                            <img className="mapaproducto2" src={this.props.location.logo}></img>


                        </div>
                    </div>

                    <a href={link} target="_blank" className="linkdbrand">
                        {link}
                    </a>
                    <div className="row bot colproductos">
                        <div className="col-lg-4">

                            {Object.keys(productos).map((e) => <Producto lang={this.state.lang} featuresnd={productos[e].featuresnd} featuresen={productos[e].featuresen} featureses={productos[e].featureses} comentarioen={productos[e].comentarioen} comentariond={productos[e].comentariond} comentarioes={productos[e].comentarioes} nombrees={productos[e].nombrees} nombrend={productos[e].nombrend} nombreen={productos[e].nombreen} imagen={productos[e].imagen}></Producto>)}

                        </div>
                        <div className="col-lg-4">
                            {imagen1}

                        </div>
                        <div className="col-lg-4">
                            {imagen2}

                        </div>
                    </div>
                </div>

                <Footer lang={this.state.lang}></Footer>
            </div>
        );
    }
}

export default DetailBrand;