import React, { Component } from 'react';
import Oficina from './Oficina.js';
import '../css/contactus.css';
import Map from './Map.js';
import pdfes from '../copyrightes.pdf';
import pdfen from '../copyrighten.pdf';
import pdfnd from '../copyrightnd.pdf'

class ContactUs extends Component {
    render() {
        let contact = "¡MANTENGÁMONOS EN CONTACTO!";
        let copy = " Política de Copyright ©️ 2021 - Todos los derechos reservados";
        let download = "DESCARGAR";
        let pdf = pdfes;
        if (this.props.lang === 'en') {
            contact = "LET´S KEEP IN TOUCH!";
            copy = "Copyright policy ©️ 2021 - All rights reserved";
            download = "DOWNLOAD";
            pdf = pdfen;
        } else if (this.props.lang === 'nd') {
            contact = "LATEN WE CONTACT HOUDEN!";
            copy = "Copyrightbeleid © ️ 2021 - Alle rechten voorbehouden";
            download = "Downloaden";
            pdf = pdfnd;
        }
        return (
            <div className="divcontact" id="divcontact">
                <h1 className="h1contact">
                    {contact}
                </h1>
                <div className="row bot">
                    <div className="col-lg-3 col-sm-6">
                        <Oficina lang={this.props.lang} titulo={"BOGOTÁ, COL"} pos={"6.2165684° N, -75.5901733° W"} correo={"info@naplesprimeci.com"} tel={"+57 601 390 4099"} dir={"Av. Carrera 9 # 115-06/30 Of. 2901"}>

                        </Oficina>
                    </div>
                    <div className="col-lg-3  col-sm-6">
                        <Oficina lang={this.props.lang} titulo={"MEDELLÍN, COL"} pos={"4.6929419 N, -74.0348538,19.56° W"} correo={"info@naplesprimeci.com"} tel={"+57 604 322 9279"} dir={"Calle 8B # 65-191 Of. 339"}>

                        </Oficina>
                    </div>


                </div>
                <div className="row bot divmap" style={{ marginLeft: "2.8vw", marginTop: "2vw" }}>
                    <Map >

                    </Map>
                </div>

                <div>

                    <div >
                        <div className="row bot divcopy" >
                            <p>{copy}</p>

                        </div>

                        <div className="row bot" style={{ justifyContent: "center" }}>
                            <button className="botoncopy">
                                <a href={pdf} className="adownload" download="copyright">

                                    {download}
                                    <i class="fa fa-download" ></i>
                                </a>
                            </button>

                        </div>
                        

                    </div>


                </div>
            </div>
        );
    }
}

export default ContactUs;
