import React, { Component } from 'react';
import '../css/menu.css';
import $ from 'jquery';
import logotipoblanco from '../images/LogotipoBlanco.png';
import menosblanco from '../images/MenosBlanco.png';
import flechamenu from '../images/FlechaVistasMoviles.png';
import logoblanco from '../images/LogosímboloBlanco.png';
import telefono from '../images/IconoTelefonoBlanco.png';
import correo from '../images/IconoMailBlanco.png';
import counterpart from 'counterpart';
import linkedin from '../images/IconoLinkedInBlanco.png';
import en from '../lang/en';
import es from '../lang/es';
import nd from '../lang/nd';
counterpart.registerTranslations('en', en);
counterpart.registerTranslations('es', es);
counterpart.registerTranslations('nd', nd); 

class Menu extends Component {
    state = {lang: 'es'}

    changeLang = (l) => {
        this.setState({ lang: l });
        this.props.handleLang(l);
        counterpart.setLocale(l);


    }
    componentDidUpdate = () =>{
        if (this.state.lang === 'es') {
            let tool1 = document.getElementById("menutooltrabaja");
            $(tool1).tooltip().attr('data-original-title', "<div> <p> Únete a nuestro equipo! Buscamos profesionales talentosos y proactivos que quieran unirse a nuestro equipo</p> <p>info@naplesprimeci.com</p><p> Gracias por su interés, nos comunicaremos con usted.</p></div>");
            let tool2 = document.getElementById("menutoolselector");
            $(tool2).tooltip().attr('data-original-title', "<div> <p> Próximamente!</p></div>");



        }
        if (this.state.lang === 'en') {
            let tool1 = document.getElementById("menutooltrabaja");
            $(tool1).tooltip().attr('title', "<div> <p> Join our team! We are looking for talented and proactive professionals who want to join our team</p> <p>info@naplesprimeci.com</p><p> Thank you for your interest, we'll get back to you</p></div>");
            $(tool1).tooltip().attr('data-original-title', "<div> <p> Join our team! We are looking for talented and proactive professionals who want to join our team</p> <p>info@naplesprimeci.com</p><p> Thank you for your interest, we'll get back to you</p></div>");
            let tool2 = document.getElementById("menutoolselector");
            $(tool2).tooltip().attr('data-original-title', "<div> <p> Comming soon!</p></div>");


        }
        if (this.state.lang ==='nd') {
            let tool1 = document.getElementById("menutooltrabaja");
            $(tool1).tooltip().attr('title', "<div> <p> ND Join our team! We are looking for talented and proactive professionals who want to join our team</p> <p>info@naplesprimeci.com</p><p> Thank you for your interest, we'll get back to you</p></div>");
            $(tool1).tooltip().attr('data-original-title', "<div> <p> ND Join our team! We are looking for talented and proactive professionals who want to join our team</p> <p>info@naplesprimeci.com</p><p> Thank you for your interest, we'll get back to you</p></div>");
            let tool2 = document.getElementById("menutoolselector");
            $(tool2).tooltip().attr('data-original-title', "<div> <p>ND Comming soon!</p></div>");


        }

    }
    render() {
        let about = "Nosotros";
        let portafolio = "Portafolio";
        let alianza = "Alianzas Estratégicas";
        let contact = "Contáctanos";
        let trabaja = "Work with us" ;
        let selector = "Country Selector ";
        if(this.state.lang === "en"){
            about = "About Us";
        }else if(this.state.lang === "nd"){
            about = "ND About Us";
        }
        if(this.state.lang === "en"){
            portafolio = "Portfolio";
        }else if(this.state.lang === "nd"){
            portafolio = "ND Portfolio";
        }

        if(this.state.lang === "en"){
            alianza = "Strategic Partnerships";
        }else if(this.state.lang === "nd"){
            alianza = "ND Alianzas";
        }

        if(this.state.lang === "en"){
            contact = "Contact Us";
        }else if(this.state.lang === "nd"){
            contact = "ND Contact Us";
        }
        let classes = "menu";
        if (this.props.show) {
            classes = "menu open"
        }
       let toolcountry ='<div> Coming soon!</div>'
       let tool = "<div> <p> Join our team! We are looking for talented and proactive professionals who want to join our team</p> <p>info@naplesprimeci.com</p><p> Thank you for your interest, we'll get back to you</p></div>";
        if (this.state.lang == 'es') {
            trabaja = "Trabajemos";
            selector = "Selector de País";
            toolcountry = '<div> ¡Proximamente!</div>';
            tool = "<div> <p> ¡Únete a nuestro equipo! Estamos buscando profesionales talentosos y proactivos que quierean unirse a nuestro equipo.</p> <p>info@naplesprimeci.com</p><p> Gracias por tu interés, nosotros te responderemos pronto.</p></div>";

            
        } else if (this.state.lang === 'nd') {
            trabaja = "ND Trabajemos";
            selector = "ND Selector de País";
            toolcountry = '<div> ¡ND Proximamente!</div>';
            tool = "<div> <p>ND ¡Únete a nuestro equipo! Estamos buscando profesionales talentosos y proactivos que quierean unirse a nuestro equipo.</p> <p>info@naplesprimeci.com</p><p> Gracias por tu interés, nosotros te responderemos pronto.</p></div>";

        }

        return (
            <div id="divmenu" className={classes} style={{ backgroundColor: "rgb(211, 29, 43 )", width: "100%", zIndex: "1000"}}>
                <div className="row bot">
                    <div className="col-2" >
                        <img id="logotipoblanco" src={logotipoblanco}>
                        </img>
                    </div>
                    <div className="col-8">

                    </div>
                    <div className="col-2" id="divmenos" style={{ textAlign: "right" }}>
                        <img id="menosblanco" src={menosblanco} onClick={this.props.click}>
                        </img>
                    </div>

                </div>
                <div style={{ marginTop: "3vw" }}>
                    <a href="/#divabout">
                    <div className="row bot secc" onClick={this.props.click}>
                        <div className="col-2">
                            <img className="flechamenu" src={flechamenu}></img>
                        </div>
                        <div className="col-10" >
                            <a className="seccionesmenu">{about}</a>
                        </div>
                    </div>
                    </a>
                    <a href="#divportafolio">
                    <div className="row bot secc" onClick={this.props.click}>
                        <div className="col-2">
                            <img className="flechamenu" src={flechamenu}></img>
                        </div>
                        <div className="col-10">
                            <a className="seccionesmenu">{portafolio}</a>
                        </div>
                    </div>
                    </a>
                    <a href="/#divpartners">
                    <div className="row bot secc" onClick={this.props.click}>
                        <div className="col-2">
                            <img className="flechamenu" src={flechamenu}></img>
                        </div>
                        <div className="col-10">
                            <a className="seccionesmenu">{alianza}</a>
                        </div>
                    </div>
                    </a>
                    <a href="/#divcontact">
                    <div className="row bot secc" onClick={this.props.click}>
                        <div className="col-2">
                            <img className="flechamenu" src={flechamenu}></img>
                        </div>
                        <div className="col-10">
                            <a className="seccionesmenu">{contact}</a>
                        </div>
                    </div>
                    </a>
                    
                </div>
                <div className="row bot">
                    <div className="col" style={{ paddingRight: "20px", marginTop: "40vw" }}>
                        <div className="row bot divrowmenu">
                            <div className="col-10">
                            </div>
                            <div className="col-2" onClick={() => {this.changeLang("en"); this.props.click();}} style={{ borderBottom: "black 2px solid", paddingLeft: "5px", paddingRight: "0" }}>
                                <a className="rowmenu">EN</a>
                            </div>

                        </div>
                        <div className="row bot divrowmenu">
                            <div className="col-10">
                            </div>
                            <div className="col-2"  onClick={() =>  {this.changeLang("es"); this.props.click();}}style={{ borderBottom: "black 2px solid", paddingLeft: "5px" , paddingRight: "0"}}>
                                <a className="rowmenu">ES</a>
                            </div>

                        </div><div className="row bot divrowmenu">
                            <div className="col-10">
                            </div>
                            {/* <div className="col-2" onClick={() => {this.changeLang("nd"); this.props.click();}} style={{ borderBottom: "black 2px solid", paddingLeft: "5px", paddingRight: "0" }}>
                                <a className="rowmenu">ND</a>
                            </div> */}

                        </div>
                    </div>



                </div>
                <div style={{ marginTop: "20vw" }}>
                    <div className="row bot secc">

                        <div className="col">
                            <a className="seccionestool" id="menutooltrabaja" data-toggle="tooltip" data-html="true" title={tool}>{trabaja}</a>
                        </div>
                    </div>
                    {/* <div className="row bot secc">

                        <div className="col">
                            <a className="seccionestool" data-toggle="tooltip" data-html="true" title={tooldir}>CONTACT DIRECTORY</a>
                        </div>
                    </div> */}
                    <div className="row bot secc">

                        <div className="col">
                            <a className="seccionestool" id="menutoolselector" data-toggle="tooltip" data-html="true" title={toolcountry} >{selector}</a>
                        </div>
                    </div>

                </div>
                <div className="footermenu">
                    <div className="row bot ">
                        <div className="col-2">
                            <img id="logomenu" src={logoblanco}>
                            </img>
                        </div>
                        <div className="col-4">

                        </div>
                        <div className="col-2 diviconos">
                        <a href="tel:+57(1)3904099"> 
                                <img className="iconosmenu" src={telefono}></img>

                                </a>
                        </div>
                        <div className="col-2 diviconos">
                        <a href="mailto:info@naplesprimeci.com">
                                <img className="iconosmenu"  src={correo}></img>
                                </a>
                        </div>
                        <div className="col-2 diviconos">
                        <a href="https://www.linkedin.com/company/naples-prime-sas" target="_blank">
                                      <img className="iconosmenu"  src={linkedin}></img>
                        </a>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Menu;