import React, { Component } from 'react';
import flechaimports from '../images/flechaimportsnegra.png'
import '../css/portafolio.css';
import {Link} from 'react-router-dom';
class Portafolio extends Component {
    render() {
        let styleex = { fontSize: "7vw", marginTop: "-4%", marginLeft: "15%", color: "black", marginBottom: "0", marginRight: "3vw" }
        let styleim = { fontSize: "7vw", marginTop: "4%", marginLeft: "4%", color: "black", marginBottom: "0" }
        if (this.props.lang == "es") {
            styleex = { fontSize: "5vw", marginTop: "-4%", marginLeft: "5%", color: "black", marginBottom: "0", marginRight: "3vw" }
            styleim = { fontSize: "5vw", marginTop: "4%", marginLeft: "4%", color: "black", marginBottom: "0" }

        }
        let imp = "IMPORTACIONES";
        let exp = "EXPORTACIONES";
        let imptext = "Trayendo a casa";
        let exptext = "Enviando de casa";
        if (this.props.lang === "en") {
            imp = "IMPORTS";
            exp = "EXPORTS";
            imptext ="Getting things home";
            exptext = "Sending things home";
        }
        else if (this.props.lang === "nd") {
            imp = "INVOER";
            exp = "EXPORT";
            imptext ="Dingen thuis krijgen";
            exptext = "Dingen naar huis sturen";
        }
        let newto = {
            pathname: "/productos",
            
            lang: this.props.lang,
           
        };
        let newto2 = {
            pathname: "/productosexp",
            
            lang: this.props.lang,
           
        };
        return (
            <div id="divportafolio">
                <div className='row bot' style={{ marginTop: "5%" }}>
               

                    <div className="col-lg-6 col-sm-12 divport import" id="divimport">
                    <Link className="a2"to={newto}>
                    <p id="tituloimports" style={styleim}>{imp}</p>
                </Link>
                      
                        <div className="row bot">
                            <div id="divflechaimport" className="col-2">
                                <img id="flechaimports" src={flechaimports}></img>


                            </div>
                            <div className="col-10" >
                                <p id="textoimport" > {imptext}</p>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-6 col-sm-12 divport export" id="divexport">
                        <a className="a2" href="/productosexp">
                        <Link className="a2"to={newto2}>
                        <div className="row bot">
                                <div className="col-8" style={{ textAlign: "right" }}>
                                    <p id="textoexport" > {exptext}</p>
                                </div>
                                <div id="divflechaexport" className="col-4">

                                </div>

                            </div>
                            <div style={{ textAlign: "right" }}>
                                <p id="tituloexport" style={styleex}>{exp}</p>
                            </div>
                     </Link>
                            

                        </a>



                    </div>
                </div>
            </div>
        );
    }
}

export default Portafolio;