import React, { Component } from 'react';
import logoblanco from '../images/Logo en blanco para el home.png';
import planetaxd from '../images/planetaxd.png';
import flecharoja from '../images/flecha roja 1.png';
import cruzblanca from '../images/Sum blanco home.png'
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import en from '../lang/en';
import es from '../lang/es';
import nd from '../lang/nd';
counterpart.registerTranslations('en', en);
counterpart.registerTranslations('es', es);
counterpart.registerTranslations('nd', nd);
counterpart.setLocale('es');
class Home extends Component {
    constructor(props) {
        super(props);
        this.state = { lang: "es" };

    }

    changeLang = (l) => {
        this.setState({ lang: l });
        this.props.handleLang(l);
        counterpart.setLocale(l);

    }
    componentDidMount() {
        $(function () {
            $('[data-toggle="tooltip"]').tooltip()
        })

    }
   
    componentDidUpdate(){
        if(this.state.lang==='es'){
           
            let toolnd=  document.getElementById("ndhome");

            $(toolnd).tooltip().attr('data-original-title', "<div> Próximamente!</div>");

        }
        if(this.state.lang==='en'){
          
            let toolnd=  document.getElementById("ndhome");

            $(toolnd).tooltip().attr('data-original-title', "<div> Coming soon!</div>");

        }
      
       

    }
    render() {
        let es, en, nd;
        if (this.state.lang ==="en") {
            es = "";
            en = "bold";
            nd = "";
        }
        if (this.state.lang ==="es") {
            es = "bold";
            en = "";
            nd = "";
        }
        if (this.state.lang ==="nd") {
            es = "";
            en = "";
            nd = "bold";
        }
      
        
        return (
            <div id="divHome" style={{ width: "100%" }}>

                <div className="row bot" style={{ paddingLeft: "3vw" }}>
                    <div className="col">
                        <img id="logohome" src={logoblanco} onClick={this.props.click}></img>
                    </div>

                    <div className="col" style={{ textAlign: "right", paddingRight: "2vw", width: "100%" }}>
                        <div>
                            <button style={{ fontWeight: en, fontSize: "1.5vw" }} className="idiomas" onClick={() => this.changeLang("en")}>
                                EN  |
                        </button>
                            <button style={{ fontWeight: es, fontSize: "1.5vw" }} className="idiomas" onClick={() => this.changeLang("es")}>
                                ES  |
                        </button>
                            <button style={{ fontWeight: nd, fontSize: "1.5vw" }}  className="idiomas"  onClick={() =>this.changeLang("nd")}>
                                ND
                        </button>
                        </div>


                        <div  className="col divcruz" style={{ height: "50px", width: "100%" }}   onClick={this.props.click}>
                            
                            <img id="cruzblanca" src={cruzblanca} ></img>
                        </div>
                    </div>


                </div>
                <div className="row bot" id="divrow2home" style={{ paddingLeft: "3vw" }}>
                    <div id="divtextohome" className="col-lg-4 col-sm-12" style={{ paddingBottom: "0px" }}>

                        <div>
                            <Translate content="homeheader" component="h1" id="headerhome"></Translate>

                            <Translate content="homeintroduccion" component="p" id="textohome"></Translate>

                        </div>
                    </div>
                    <div className='col-lg-4 col-sm-12' id="containerpulse" href="#divheader">
                        <a href="#divheader"> <button className="pulse-button" ><img id="flecharojahome" src={flecharoja}></img></button></a>

                    </div>
                    <div id="divplanetaxd" className="col-4" style={{ paddingRight: "0px" }}>
                        <img id="planetaxd" src={planetaxd}></img>
                    </div>

                </div>



            </div>
        );
    }
}

export default Home;