export default {
    
    homeheader: "Origines conectados",
    homeintroduccion: "Naples Prime es una Comercializadora Internacional 100% colombiana que a través del acompañamiento y asesorías a sus clientes y proveedores busca satisfacer las necesidades latentes del mercado local y global, e incentivar el comercio reciproco entre aliados estratégicos apoyando el desarrollo social.",
    headabout: "Nosotros",
    galeriah1: "NUESTRO PROPÓSITO ES",
    galeriat2: "HACER DE LA TECNOLOGÍA EL CENTRO DE LAS CADENAS DE SUMINISTRO GLOBALES, CÉNTRICAS Y RESPONSABLES",
    galeriat3: "Estamos comprometidos con el progreso de nuestros grupos de interés, desde el inicio adoptando una filosofía empresarial fundada en la Responsabilidad Social como nuestra visión, basada en la ética, el cuidado del medio ambiente y la sostenibilidad. Queremos contribuir al desarrollo social de comunidades vulnerables y lo demostramos a través de la creación de proyectos junto con el apoyo del gobierno, generando empleo y ampliando nuestra oferta de productos.",
    aboutvalues: 'ÉTICA • LIDERAZGO • RESPONSABILIDAD • COMPROMISO • CALIDAD • INNOVACIÓN',
    aboutparrafo: "A través del liderazgo y la ética en su trabajo, Naples Prime demuestra su compromiso con la labor desempeñada de manera responsable e íntegra, siempre teniendo como prioridad los máximos estándares de calidad e incorporando las más recientes innovaciones en sus procesos comerciales.",
    aboutparrafo2: "Así mismo, trabajando en aras al desarrollo de métodos sostenibles que generen valor agregado a todos los eslabones en la cadena, recurre al uso de últimas tecnologías en los sistemas de suministro y distribución de productos en bruto y elaborados, que no sólo garantizan la conservación del planeta, sino la optimización de recursos, generando a su vez un ciclo de vida circular que retribuye en todos los niveles asociados",
    abouthead: "ORIGENES CONECTADOS",
    abouthead2: "NUESTRA MISIÓN",
    aboutmission: "Ser el puente mediador que agiliza la ejecución del ejercicio empresarial entre corporaciones nacionales e internacionales, minorías locales y actores de distintas nacionalidades, cuyos orígenes limitan su capacidad de explorar, incursionar e interconectarse con otros mercados, facilitando y asegurando que los procesos comerciales ejecutados generen valor a través de la sostenibilidad y dejen huella social, ofreciendo soluciones óptimas ajustadas a las necesidades intrínsecas de las diferentes colectividades involucradas, permitiendo la expansión de nuestras capacidades y contribuyendo así con el desarrollo social y económico de nuestro país. ",
    abouthead3: "NUESTRA VISIÓN",
    aboutvision: "Seremos líderes activos en la oferta al mercado de un portafolio altamente diversificado compuesto por productos nacionales e internacionales cuidadosamente seleccionados que compitan en el entorno global por su calidad y excepcionalidad, contribuyendo así al fortalecimiento de minorías y comunidades locales vulneradas que buscan un sustento estable y sostenible en el tiempo, destacando nuestro compromiso en el trabajo a través de la consolidación de procesos productivos eficientes bajo los más altos estándares de calidad que generen confianza y seguridad a nuestros actuales y futuros aliados. ",
    abouthead4: "NUESTRA HISTORIA",
    history1: "Naples Prime se gesta en septiembre del año 2015 en Bogotá, Colombia, como un proyecto con el cual su fundador toma como base su experiencia y trayectoria en negociación internacional para constituir una compañía sostenible y sustentable que satisfaga la necesidad de pequeños productores y fabricantes nacionales para comercializar y posicionar sus mercancías en el exterior, así mismo, suplir la demanda local con productos de alta calidad provenientes de aliados estratégicos en el exterior.",
    history2: "En septiembre del año 2017 Naples Prime es constituida como empresa, dando inicio a sus operaciones comerciales y a la construcción de relaciones sólidas con sus clientes y proveedores. Estos han sido el soporte de Naples Prime para alcanzar un crecimiento integral a lo largo del tiempo, que no solo le ha permitido ser rentable, sino también obtener la credibilidad que el sector requiere. Lo anterior ha posibilitado que Naples Prime sea de apoyo a las autoridades regionales colombianas en proyectos de desarrollo económico de comunidades ubicadas en zonas apartadas del territorio colombiano, generando sentido de pertenencia y solidificación a la marca en crecimiento.",
    history3: " Avanzando en los pasos que su actividad económica requería, en octubre del año 2019 el Ministerio de Comercio, Industria y Turismo – MINCIT, otorga a Naples Prime SAS CI la resolución que le autoriza como Sociedad de Comercialización Internacional. Hoy, Naples Prime es una empresa consolidada y conocedora de su negocio, cuenta con varias sucursales en las principales ciudades del país y con un equipo humano con las competencias que demanda el mercado global en las distintas áreas del comercio internacional. Sumado a lo anterior, sigue fortaleciendo y construyendo alianzas con socios nacionales e internacionales clave por medio del desarrollo de objetivos estratégicos que apuntan en dirección del alcance de la Visión que se ha formulado."
}