import React, { Component } from 'react';
import flechaheader from '../images/flecha negra menu.png';
import '../css/header.css';
class FlechaHeader extends Component {
    constructor(props) {
        super(props);
        this.state = { display: "none" };

    }
    clickHander = () => {
        if (this.state.display == "none") {
            this.setState({ display: "inline" });
        }
        else {
            this.setState({ display: "none" });
        }


    }

    render() {
        let margin = "0";
        let display = "none";
        let color = "black";
        if (this.props.numact != this.props.num) {
            margin = "0px";

        } else {
            margin = "0";
            display = "inline";
            color = "rgb(227, 24, 50 )"
        }
        return (
            <div>
                <a href={"/"+this.props.href} className="hoverheader">

                    <p className="textoheader" onClick={this.clickHander} style={{ marginTop: margin , color:color}}>
                        {this.props.texto}
                    </p>
                    
                </a>

            </div>
        );
    }
}

export default FlechaHeader;