import React, { Component } from 'react';
import '../css/marca.css';
import cruzheader from '../images/Sum rojo.png';
import menos from '../images/minus portafolio.png';


class Marcaexp extends Component {
    state = {
        abierto: false
    }
    componentDidMount() {

    }
    handleClick = () => {

        this.setState(prevState => ({
            abierto: !prevState.abierto
        }));

    }
    render = () => {

        let tags = [];
        let pais = "";
        let sub = "";
        let imagen = "";
        let descripcion = "";
        let nombre = "";
        if (this.props.lang == "es") {
            tags = this.props.tagses;
            pais = this.props.paises;
            sub = this.props.subes;
            descripcion = this.props.descripciones;
            nombre = this.props.nombrees;
        } else if (this.props.lang == "en") {
            tags = this.props.tagsen;
            pais = this.props.paisen;
            sub = this.props.suben;
            descripcion = this.props.descripcionen;
            nombre = this.props.nombreen;
        }else if (this.props.lang == "nd") {
            tags = this.props.tagsnd;
            pais = this.props.paisnd;
            sub = this.props.subnd;
            descripcion = this.props.descripcionnd;
            nombre = this.props.nombrend;
        }

        if (!this.state.abierto) {
            imagen = (<a onClick={this.handleClick} data-toggle="collapse" data-target={"#collapse" + this.props.nombrees.replace(/\s+/g, '')} role="button" aria-expanded="false" aria-controls={"#collapse" + this.props.titulo}>
                <div>
                    <img className="cruzcategoriaexp" src={cruzheader}>
                    </img>
                </div>

            </a>
            );
        } else {
            imagen = (<a onClick={this.handleClick} data-toggle="collapse" data-target={"#collapse" + this.props.nombrees.replace(/\s+/g, '')} role="button" aria-expanded="false" aria-controls={"#collapse" + this.props.titulo}>
                <div>
                    <img className="menoscategoriaexp" src={menos}>
                    </img>
                </div>
            </a>
            );
        }
        return (
            <div className="divmarca col-lg-4 col-sm-12">
                <div className="row bot2" style={{ textAlign: "center" }}>

                    <div className="divimgmarca">

                        <img className="imgmarca" src={this.props.img}>
                        </img>

                    </div>


                </div>

                <div className="row bot2 lineamarca" style={{ marginTop: "1vw" }}>
                    <div className='col-10 nop'>
                        <p className='nombremarca'>
                            {nombre}
                        </p>
                    </div>
                    <div className='col-2 nop' style={{ textAlign: 'right' }}>
                        {imagen}
                    </div>
                </div>
                <div className="row bot2 lineamarca">
                    <p className='paismarca'>
                        <div className="collapse row bot hola" id={"collapse" + this.props.nombrees.replace(/\s+/g, '')}>
                            {descripcion}
                        </div>
                    </p>
                </div>
                <div className="row bot2 lineamarca divfinal" >
                    {tags.map((tag) => <div className="divtag">{tag}</div>)}
                </div>

            </div>
        );
    }
}

export default Marcaexp;