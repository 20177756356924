import React, { Component } from 'react';
class Brand extends Component {
    render() {
        let more = "";
        if(this.props.lang =="es"){
            more = "Más información";
        }else if(this.props.lang =="en"){
            more = "More information";
        }
        else if(this.props.lang =="nd"){
            more = "Meer informatie";
        }
        return (
            <div className="divbrand">
                <div className="row">
                    <div className="col-8" style={{textAlign:"left"}}>
                        <h1 className="nombreallie">
                            {this.props.nombre}
             </h1>

                    </div>
                    <div className="col-4">
                    <img className="imgbrand" src={this.props.logo}>
                    </img>
                    </div>
                    
                </div>

                <div className="infoallie">
                  {more}<a href={this.props.link} target="_blank"><span className='linkallie'> {this.props.link}</span></a>
                </div>
            </div>
        );
    }
}

export default Brand;