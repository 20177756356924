import React, { Component } from 'react';
import '../css/partners.css';
class Allie extends Component {
    
    render() {
        let more = "";
        if(this.props.lang ===  "es"){
            more = "Más información";
        }else if(this.props.lang === "en"){
            more = "More information";
        }else if(this.props.lang === "nd"){
            more = "Meer informatie";
        }       
        return (
            <div className="divallie">
                <h1 className="nombreallie">
                    {this.props.nombre}
             </h1>
                <p className="textoallie">
                   {this.props.text}
             </p>
             <div className="infoallie">
             {more} <a href={this.props.link } target="_blank"><span className='linkallie'> {this.props.link}</span></a>
             </div>
            </div>
        );
    }
}

export default Allie;