import React, { Component } from 'react';
import cruzheader from '../images/Sum rojo.png';
import menos from '../images/minus portafolio.png';
import mapa from '../images/Mapa españa.png';
class Producto extends Component {
    state = {
        abierto: false
    }
    
    handleClick = () => {
        this.setState(prevState => ({
            abierto: !prevState.abierto
        }));
    }

    render() {
        let comentario, features, nombre, foto, mas,menosin;
        let imagen;
        if (this.props.lang ==="es") {
            comentario = this.props.comentarioes;
            features = this.props.featureses.split(',');
            nombre = this.props.nombrees;
            foto = this.props.imagen;
            mas = "Más Información";
            menosin = "Menos Información";
        } else if (this.props.lang ==="en") {
            comentario = this.props.comentarioen;
            features = this.props.featuresen.split(',');
            nombre = this.props.nombreen;
            foto = this.props.imagen;
            mas = "More Information";
            menosin = "Less Information";
        }else if (this.props.lang ==="nd") {
            comentario = this.props.comentariond;
            features = this.props.featuresnd.split(',');
            nombre = this.props.nombrend;
            foto = this.props.imagen;
            mas = "Meer informatie ";
            menosin = "Minder informatie";
        }
        if (!this.state.abierto) {
            imagen = (
                <div className='row bot'>

                    <div className="col-3" >
                        <div>
                            <a data-toggle="collapse" className="acollapse" onClick={this.handleClick} data-target={"#collapse" + this.props.nombrees.replace(/\s+/g, '').toLowerCase()} role="button" aria-expanded="false" aria-controls={"#collapse" + this.props.nombrees.replace(/\s+/g, '').toLowerCase()}>

                                <img className="cruzcategoria2" src={cruzheader}>
                                </img>


                            </a>
                        </div>
                    </div>


                    <div className="col-9">
                        <p className="masinfo">
                            {mas}
                        </p>

                    </div>

                </div>

            );
        } else {
            imagen = (

                <div className='row bot' >
                    <div className="col-3">
                        <div>
                            <a data-toggle="collapse" className="acollapse" onClick={this.handleClick} data-target={"#collapse" + this.props.nombrees.replace(/\s+/g, '').toLowerCase()} role="button" aria-expanded="false" aria-controls={"#collapse" + this.props.nombrees}>

                                <img className="menoscategoria2" src={menos}>
                                </img>
                            </a>
                        </div>

                    </div>


                    <div className="col-9">
                        <p className="masinfo">
                            {menosin}
                        </p>

                    </div>

                </div>

            );
        }
        return (
            <div style={{ marginTop: "3vw" }}>
                <div className="divatributo">
                    <h5 className="nombreproducto">
                        {nombre}
                    </h5>
                    {features.map((e) => <p>{e}</p>)}
                </div>

                <div className="divproducto">



                    {imagen}



                </div>
                <div className="collapse row bot colprod" id={"collapse" +this.props.nombrees.replace(/\s+/g, '').toLowerCase()}>
                    <p>
                        {comentario}

                    </p>
                    <img className="mapaproducto" src={foto}>
                    </img>
                </div>
            </div>
        );
    }
}

export default Producto;