import firebase from 'firebase'


var firebaseConfig = {
   apiKey: process.env.FIREBASEAPIKEY2,
   authDomain: "naplesprime-website.firebaseapp.com",
   databaseURL: "https://naplesprime-website-default-rtdb.firebaseio.com",
   projectId: "naplesprime-website",
   storageBucket: "naplesprime-website.appspot.com",
   messagingSenderId: "823480836546",
   appId: "1:823480836546:web:fe93db47154ca412c3365b"

}
if (!firebase.apps.length) {
    var fire = firebase.initializeApp(firebaseConfig);
 }else {
    var fire = firebase.app(); 
 }
export default fire;