import React, { Component } from 'react';
import '../css/aboutus.css';
import logo from '../images/Logo NP rojo.png';
import imagen from '../images/slide1.jpeg';
import Translate from 'react-translate-component';
import imagenmision from '../images/mision2.jpg';
import History from './History.js';

class AboutUs extends Component {
    render() {
        return (
            <div className="divabout" id="divabout">
                <div className="row bot about1">
                    <div className="col-2">
                        <img id="logoabout" src={logo}>
                        </img>
                    </div>
                    <div className="col-10 divvalores">

                        <Translate content="aboutvalues" component="h1" className="valores"></Translate>
                        <Translate content="aboutparrafo" component="p" className="textoabout"></Translate>


                    </div>
                </div>
                <div className="row bot">
                    <div className="col-5" >
                        <img id="imagenabout" src={imagenmision}>
                        </img>
                    </div>
                    <div className="col-7">
                        <Translate content="aboutparrafo2" component="p" className="textoabout2"></Translate>

                    </div>
                </div>
                <div className="row bot">
                    <div className="col" style={{ marginLeft: "2vw" }}>
                        <Translate content="abouthead" component="h1" className="valores"></Translate>


                        <div className='row bot about2'>
                            <div className='col'>
                                <div className="seccionabout">
                                    <Translate content="abouthead2" component="h2" className="tituloseccion"></Translate>
                                    <Translate content="aboutmission" component="p" className="textoseccion"></Translate>


                                </div>
                                <div className="seccionabout">
                                    <Translate content="abouthead3" component="h2" className="tituloseccion"></Translate>
                                    <Translate content="aboutvision" component="p" className="textoseccion"></Translate>



                                </div>
                                
                            </div>
                            <div className="col">
                                <img src={imagen} className="imgabout">
                                </img>
                            </div>
                            
                            <History lang={this.props.lang}>

                            </History>
                        </div>

                    </div>


                </div>
            </div>
        );
    }
}

export default AboutUs;