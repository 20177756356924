import React, { Component } from 'react';
import GoogleMaps from 'simple-react-google-maps';
class Map extends Component {
    render() {
        return (
            <div>
                <GoogleMaps
                    apiKey={"AIzaSyDSfz0w3QP1QRBP5WOodmQSy3edSRJGuyI"}
                    style={{ height: "50vh", width: "90vw", marginBottom: "5vw", marginLeft: "3vw" , marginTop: "3vh"}}
                    
                    zoom={6}
                    center={{ lat: 4.693441282369053, lng: -74.03453205987252}}
                    markers={
                        [
                            { lat: 4.693441282369053, lng: -74.03453205987252 },
                            { lat: 6.216024144947138, lng: -75.58787741754392},
                            { lat: 26.224255649677634, lng: -81.77701127322912 }
                        ]
                    }>
                   
                </GoogleMaps>

            </div>
        );
    }
}

export default Map;