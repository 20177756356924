import React, { Component } from 'react';
import '../css/galeria.css';
import slide1 from '../images/vision.JPG';
import slide2 from '../images/slide2.jpeg';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import Translate from 'react-translate-component';


class Galeria extends Component {

    render() {
        let conoce = '';
        if(this.props.lang ==="es"){
            conoce = "Conoce más";
        }else if(this.props.lang ==="en"){
            conoce = "Learn more";
        }else if(this.props.lang ==="nd"){
            conoce = "Kom meer te weten";
        }
        return (
            <div id="divgaleria">
                <div className="row bot">

                </div>
                <div className="row bot" style={{ marginTop:"3vw"}}>
                    <div className="col-lg-6 col-sm-12" style={{ textAlign: "right", paddingLeft: "8vw"}}>
                    <Translate  style={{ fontWeight: "700", lineHeight: "80%", marginTop:"4vw" }} content="galeriah1" component="p" id="texto1"></Translate>

                    <Translate   style={{  fontWeight: "200", lineHeight: "110%"}} content="galeriat2" component="p" id="texto2"></Translate>

                    <Translate  style={{  fontWeight: "400",  textAlign: "left"}} content="galeriat3" component="p" id="texto3"></Translate>

                      
                        <div id="divbotongaleria">
                            <a href="#divabout">
                            <button id="botongaleria"><p style={{marginTop: "5px" }}><mark style={{color: " rgb(144,12,48)" ,background: "none"}}>{conoce}</mark> <mark style={{color: " white" ,background: "none"}}>   {this.props.lang=='en'? "about us": ""}</mark></p></button>

                            </a>
                        </div>
                  
                    </div>
                    <div id="divcarousel" className="col-lg-6 col-sm-12">
                        <AliceCarousel autoPlay autoPlayInterval="3000"  disableButtonsControls="true" infinite="true">
                            <img className="imggaleria" src={slide1} className="sliderimg" />
                            <img className="imggaleria" src={slide2} className="sliderimg" />

                        </AliceCarousel>
                    </div>
               
                </div>
            </div>
        );
    }
}

export default Galeria;