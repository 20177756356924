import React, { Component } from 'react';
import '../css/marca.css';
import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";


class Marca extends Component {
   
        
    render() {
        let tags = [];
        let pais = "";
        let sub = "";

        if (this.props.lang == "es") {
            tags = this.props.tagses;
            pais = this.props.paises;
            sub = this.props.subes;
        } else if (this.props.lang == "en") {
            tags = this.props.tagsen;
            pais = this.props.paisen;
            sub = this.props.suben;
        }else if (this.props.lang == "nd") {
            tags = this.props.tagsnd;
            pais = this.props.paisnd;
            sub = this.props.subnd;
        }
        let newTo = {
            pathname: "/importaciones/" + this.props.nombre,
            categoria: this.props.categoria,
            lang: this.props.lang,
            logo: this.props.img,
            imagen1: this.props.imagen1,
            imagen2: this.props.imagen2
        };
        return (
            <div className="divmarca col-lg-4 col-sm-12">
                <div className="row bot2" style={{ textAlign: "center" }}>

                    <div className="divimgmarca">
                        <Link to={newTo}>
                            <img className="imgmarca" src={this.props.img}>
                            </img>
                        </Link>
                    </div>


                </div>
                <div className="row bot2 lineamarca" style={{ marginTop: "1vw" }}>
                    <div className='col-6 nop'>
                        <p className='nombremarca'>
                            {this.props.nombre}
                        </p>
                    </div>
                    <div className='col-6 nop' style={{ textAlign: 'right' }}>
                        <p className='sub'>
                            {sub}
                        </p>
                    </div>
                </div>
                <div className="row bot2 lineamarca">
                    <p className='paismarca'>
                        {pais}
                    </p>
                </div>
                <div className="row bot2 lineamarca divfinal" >
                    {tags.map((tag) => <div className="divtag">{tag}</div>)}
                </div>
            </div>
        );
    }
}

export default Marca;