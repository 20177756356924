import React, { Component } from 'react';
import cruzheader from '../images/Sum rojo.png';
import menos from '../images/minus portafolio.png';
import Marca from './Marca.js';
import '../css/categoria.css';
import fire from '../fire.js';

class Categoria extends Component {
    state = {
        abierto: false,
        marcas: []
    }
    handleClick = () => {
        this.setState(prevState => ({
            abierto: !prevState.abierto
        }));
    }
    componentDidMount = () => {
        const that = this;
        let nom = this.props.nombre;
        let marcas=[];
        let marcasdetalles= [];
    
        fire.database().ref('imports/'+this.props.nombre.replace(/\s+/g, '').toLowerCase()).once('value',  (snapshot)=> {
            snapshot.forEach(
                function (ChildSnapshot) {
                    let nombre = ChildSnapshot.val().nombre;
                    let imagen = ChildSnapshot.val().imagen;
                    let paises = ChildSnapshot.val().paises;
                    let paisen = ChildSnapshot.val().paisen;
                    let paisnd = ChildSnapshot.val().paisnd;
                    let subcates = ChildSnapshot.val().subcates;
                    let subcaten = ChildSnapshot.val().subcaten;
                    let subcatnd = ChildSnapshot.val().subcatnd;
                    let tagses = ChildSnapshot.val().tagses;
                    let tagsen = ChildSnapshot.val().tagsen;
                    let tagsnd = ChildSnapshot.val().tagsnd;
                    let imagen1 = ChildSnapshot.val().imagen1;
                    let imagen2 = ChildSnapshot.val().imagen2;
                   
                    let item = {nombre: nombre, imagen: imagen, imagen1: imagen1, imagen2: imagen2, paisnd: paisnd, paises: paises, paisen: paisen, subcatnd: subcatnd, subcates: subcates,subcaten: subcaten, tagses: tagses, tagsen: tagsen,  tagsnd: tagsnd};
                    fire.database().ref('imports/'+nom.replace(/\s+/g, '').toLowerCase()+'/'+nombre+'/detalle').once('value',  (snap) =>{
                       
                       let detallep= snap.val();
                     
                        marcasdetalles.push(detallep); 
                      
                        item.detalle  =detallep;
                    });
                  
                   marcas.push(item);
                  
                    that.setState({marcas, marcasdetalles})
                
                }
            );
        })
      
       
    }


    render() {
        let imagen;
        let from ="";
        if(this.props.lang === 'en'){
            from  = "IMPORTED FROM";
        }else if(this.props.lang=== 'es'){
            from = "IMPORTADO DE";
        }else if(this.props.lang=== 'nd'){
            from = "GEÏMPORTEERD VANUIT";
        }
        if (!this.state.abierto) {
            imagen = (<a onClick={this.handleClick} data-toggle="collapse" data-target={"#collapse" + this.props.titulo.replace(/\s+/g, '')} role="button" aria-expanded="false" aria-controls={"#collapse" + this.props.titulo}>
                <div>
                <img className="cruzcategoria" src={cruzheader}>
                </img>
                </div>
                
            </a>
            );
        }else{
            imagen = (<a onClick={this.handleClick} data-toggle="collapse" data-target={"#collapse" + this.props.titulo.replace(/\s+/g, '')} role="button" aria-expanded="false" aria-controls={"#collapse" + this.props.titulo}>
                 <div>
                <img className="menoscategoria" src={menos}>
                </img>
                </div>
            </a>
            );
        }
        return (
            <div className="divcategoria">
                <div className="row bot" style={{ marginBottom: "2vw" }}>
                    <div className=" col-2">
                        {imagen}
                    </div>
                    <div className="col-8 divtitulocat">
                        <a className="titulocategoria">
                            {this.props.titulo}
                        </a>
                    </div>
                    <div className="col-2 hidden-xs divinfocat">
                        <a className="infocat">
                            {this.props.from} <br></br>
                           {from}
                            <ol>
                                {this.props.paises.map((pais) => <li>{pais}</li>)}

                            </ol>
                        </a>
                    </div>
                </div>
                <div className="collapse row bot" style={{ margin: "0 !important" }} id={"collapse" + this.props.titulo.replace(/\s+/g, '')}>
                  
        { 
        this.state.marcas.map((e, index) => <Marca lang={this.props.lang} imagen1={e.imagen1} imagen2={e.imagen2} categoria={this.props.nombre.replace(/\s+/g, '').toLowerCase()} detalle={e.detalle} img={e.imagen} nombre={e.nombre} paisnd={e.paisnd} paises={e.paises} paisen={e.paisen} subes={e.subcates}  subnd={e.subcatnd} suben={e.subcaten} tagsnd={e.tagsnd.split(' ')} tagses={e.tagses.split(' ')} tagsen={e.tagsen.split(' ')}></Marca>)}
               
                   
                  

                </div>

            </div>
        );
    }
}

export default Categoria;