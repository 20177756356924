import React, { Component } from 'react';
import cruzheader from '../images/Sum rojo.png';
import menos from '../images/minus portafolio.png';
import Marcaexp from './Marcaexp.js';
import '../css/categoria.css';

import fire from '../fire.js';

class Categoriaexp extends Component {
    state = {
        abierto: false,
        marcas: []
    }
    handleClick = () => {
        this.setState(prevState => ({
            abierto: !prevState.abierto
        }));
       
    }
    componentDidMount = () => {
        const that = this;
        let marcas=[];
        let marcasdetalles= [];
  
        fire.database().ref('exports/'+this.props.nombre.replace(/\s+/g, '').toLowerCase()).once('value',  (snapshot)=> {
            snapshot.forEach(
                function (ChildSnapshot) {
                    let nombrees = ChildSnapshot.val().nombrees;
                    let nombreen = ChildSnapshot.val().nombreen;
                    let nombrend = ChildSnapshot.val().nombrend;
                    let descripcionen = ChildSnapshot.val().descripcionen;
                    let descripciones = ChildSnapshot.val().descripciones;
                    let descripcionnd = ChildSnapshot.val().descripcionnd;
                    let tagses = ChildSnapshot.val().tagses;
                    let tagsen = ChildSnapshot.val().tagsen;
                    let tagsnd = ChildSnapshot.val().tagsnd;
                    let imagen = ChildSnapshot.val().imagen;

                    let item = {nombrees: nombrees, nombreen: nombreen, nombrend: nombrend, imagen: imagen, descripcionen:descripcionen, descripciones:descripciones, descripcionnd:descripcionnd,  tagses: tagses, tagsen: tagsen, tagsnd: tagsnd,};
                   
                   marcas.push(item);
                  
                    that.setState({marcas, marcasdetalles})
              
                }
            );
        })
      
       
    }


    render() {
       
        let imagen;
        let from ="";
        if(this.props.lang == 'en'){
            from  = "EXPORTED FROM";
        }else if(this.props.lang== 'es'){
            from = "EXPORTADO DE";
        }
        else if(this.props.lang== 'nd'){
            from = "UITGEVOERD VAN";
        }
        if (!this.state.abierto) {
            imagen = (<a onClick={this.handleClick} data-toggle="collapse" data-target={"#collapse" + this.props.titulo.replace(/\s+/g, '')} role="button" aria-expanded="false" aria-controls={"#collapse" + this.props.titulo}>
                <div>
                <img className="cruzcategoria" src={cruzheader}>
                </img>
                </div>
                
            </a>
            );
        }else{
            imagen = (<a onClick={this.handleClick} data-toggle="collapse" data-target={"#collapse" + this.props.titulo.replace(/\s+/g, '')} role="button" aria-expanded="false" aria-controls={"#collapse" + this.props.titulo}>
                 <div>
                <img className="menoscategoria" src={menos}>
                </img>
                </div>
            </a>
            );
        }
        return (
            <div className="divcategoria">
                <div className="row bot" style={{ marginBottom: "2vw" }}>
                    <div className=" col-2">
                        {imagen}
                    </div>
                    <div className="col-8 divtitulocat">
                        <a className="titulocategoria">
                            {this.props.titulo}
                        </a>
                    </div>
                    <div className="col-2 hidden-xs divinfocat">
                        <a className="infocat">
                           <br></br>
                           {from}
                            <ol>
                                {this.props.paises?this.props.paises.map((pais) => <li>{pais}</li>):''}

                            </ol>
                        </a>
                    </div>
                </div>
                <div className="collapse row bot" style={{ margin: "0 !important" }} id={"collapse" + this.props.titulo.replace(/\s+/g, '')}>
                  
        { 
        this.state.marcas.map((e, index) => <Marcaexp key={index} lang={this.props.lang} categoria={this.props.nombre.replace(/\s+/g, '').toLowerCase()} detalle={e.detalle} img={e.imagen} nombrees={e.nombrees} nombrend={e.nombrend} nombreen={e.nombreen} descripciones={e.descripciones}  descripcionnd={e.descripcionnd} descripcionen={e.descripcionen}  tagsnd={e.tagsnd.split(' ')} tagses={e.tagses.split(' ')} tagsen={e.tagsen.split(' ')}></Marcaexp>)}
               
                   
                  

                </div>

            </div>
        );
    }
}

export default Categoriaexp;