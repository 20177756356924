export default {
    
    homeheader: "Origins connected",
    homeintroduccion: "Napels Prime is een 100% Colombiaanse Internationale Handelsonderneming die, door begeleiding en advies aan haar klanten en leveranciers, probeert te voldoen aan de behoeften van delokale en wereldwijde markt, handel tussen strategische bondgenoten aan te moedigen ensociale ontwikkeling lokaal te ondersteunen.",
    headabout: "Wij",
    galeriah1: "OM MET DE HUIDIGE",
    galeriat2: "TECHNOLOGIE DE SCHAKEL TE VORMEN VOOR EEN  WERELDWIJDE TOELEVERINGSLETEN OP EEN VERANTWOORDE EN MENSGERICHTE MANIER",
    galeriat3: "We zetten ons in voor de vooruitgang van onze stakeholders, van begin af aan met een bedrijfsfilosofie gebaseerd op maatschappelijke verantwoordelijkheid als onze visie, gebaseerd op ethiek, zorg voor het milieu en duurzaamheid. We willen bijdragen aan de sociale ontwikkeling van kwetsbare gemeenschappen en dat laten we zien door het opzetten van projecten samen met overheidssteun, het creëren van banen en het uitbreiden van ons aanbod van producten.",
    aboutvalues: 'ETHIEK • LEIDERSCHAP • VERANTWOORDELIJKHEID • BETROKKENHEID • KWALITEIT • INNOVATIE',
    aboutparrafo: "Door leiderschap en ethiek in zijn werk toont Napels Prime zijn toewijding aan verantwoord en integer uitgevoerd werk, waarbij altijd prioriteit wordt gegeven aan de hoogste kwaliteitsnormen en de nieuwste innovaties in zijn bedrijfsprocessen worden geïntegreerd.",
    aboutparrafo2: "Evenzo, werkend aan de ontwikkeling van duurzame methoden die toegevoegde waarde genereren voor alle schakels in de keten, maakt het gebruik van de nieuwste technologieën in de aan- en distributiesystemen van ruwe en verwerkte producten, die niet alleen het behoud van de planeet garanderen, maar ook de optimalisatie van hulpbronnen, waardoor op zijn beurt een circulaire levenscyclus wordt gegenereerd die op alle bijbehorende niveaus loont.",
    abouthead: "ORIGINS CONNECTED",
    abouthead2: "ONZE MISSIE",
    aboutmission: "Wij vormen de brug in de uitvoering die de uitvoering van bedrijven tussen nationale en internationale bedrijven, lokale minderheden en actoren van verschillende nationaliteiten stroomlijnt, om hun vermogen om andere markten te betreden te optimaliseren. We willen helpen hun commerciële processen te faciliteren en te waarborgen en waarde te genereren door middel van duurzaamheid, een sociale voetafdruk achterlaten en optimale oplossingen bieden die zijn afgestemd op de intrinsieke behoeften van de verschillende betrokken gemeenschappen, waardoor onze capaciteiten kunnen worden uitgebreid en zo kunnen bijdragen aan de sociale en economische ontwikkeling van ons land.",
    abouthead3: "ONZE VISIE",
    aboutvision: " We zullen actieve leiders zijn die een zeer gediversifieerd portfolio aanbieden, bestaande uit zorgvuldig geselecteerde nationale en internationale producten die wereldwijd concurreren om hun kwaliteit en uitzonderlijkheid, en zo bijdragen aan de versterking van minderheden en kwetsbare lokale gemeenschappen die in de loop van de tijd op zoek zijn naar een stabiel en duurzaam levensonderhoud, en benadrukken onze toewijding om te werken door de consolidatie van efficiënte productieprocessen onder de hoogste kwaliteitsnormen die vertrouwen en veiligheid genereren voor onze huidige en toekomstige bondgenoten.",
    abouthead4: "ONZE GESCHIEDENIS",
    history1: "Napels Prime werd in september 2015 opgericht in Bogotá, Colombia, als een project waarmee de oprichter op basis van zijn ervaring en traject in het internationale bedrijfsleven een duurzaam bedrijf wilde oprichten dat voldoet aan de behoefte van kleine producenten en nationale fabrikanten om hun producten in het buitenland op de markt te brengen en te positioneren, en ook lokale vraag te voorzien van hoogwaardige producten van strategische bondgenoten in het buitenland.",
    history2: "In september 2017 werd Napels Prime formeel opgericht als een bedrijf, dat zijn commerciële activiteiten begon en sterke relaties opbouwde met zijn klanten en leveranciers, die in de loop van de tijd zijn steun hebben gegeven aan het bereiken van een uitgebreide groei, waardoor het niet alleen winstgevend is geworden, maar ook de geloofwaardigheid heeft verkregen die de sector nodig heeft. Dit heeft het voor Napels Prime mogelijk gemaakt om regionale Colombiaanse autoriteiten te ondersteunen bij economische ontwikkelingsprojecten voor gemeenschappen in afgelegen gebieden van het Colombiaanse grondgebied, waardoor een gevoel van verbondenheid en stolling van het groeiende merk is ontstaan.",
    history3: "In oktober 2019 heeft het ministerie van Handel, Industrie en Toerisme - MINCIT, napels Prime SAS CI de resolutie verleend die het als internationale handelsonderneming toestaat. Tegenwoordig is Napels Prime een geconsolideerd bedrijf met kennis over zijn bedrijf, het heeft verschillende vestigingen in de belangrijkste steden van het land en een team met de vaardigheden die de wereldmarkt vereist op verschillende gebieden van de internationale handel. Naast het bovenstaande blijft het allianties versterken en opbouwen met belangrijke nationale en internationale partners door de ontwikkeling van strategische doelstellingen die wijzen in de richting van het toepassingsgebied van de geformuleerde visie."
}