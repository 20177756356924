import './App.css';
import Contenedor from './components/Contenedor.js';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Productos from './components/Productos.js';
import Productosexp from './components/Productosexp.js';
import DetailBrand from './components/DetailBrand.js';
import React from 'react';
import { Helmet } from "react-helmet";

const ref = React.createRef();
function App() {
  return (
    <Router>

      <div className="App" style={{ height: '100%' }} ref={ref}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Naples Prime CI</title>
          <meta property="og:title" content="Naples Prime" />
          <meta property="og:image" content="//src/images/logoxd.png"></meta>
          <link rel="canonical" href="https://naplesprime.herokuapp.com/" />
          <meta
            name="description"
            content="Naples Prime CI"
          />
        </Helmet>


        <Switch>
          <Route exact path='/' component={Contenedor}>
          </Route>
          <Route path='/productos' component={Productos}>
          </Route>
          <Route path='/productosexp' component={Productosexp}>
          </Route>
          <Route path='/importaciones/:id' component={DetailBrand}>
          </Route>
        </Switch>

      </div>
    </Router>

  );
}

export default App;
