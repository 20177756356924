import React, { Component } from 'react';
import Map from './Map';
import '../css/oficina.css';
import flecha from '../images/FlechaVistasMoviles.png'
class Oficina extends Component {
    render() {
        let consultas = "CONSULTAS GENERALES";
        let tel ="TELÉFONO Y FAX";
        let dir = "DIRECCIÓN";

        if (this.props.lang === "en"){
            consultas = "GENERAL INQUERIES";
            tel = "PHONE AND FAX";
            dir = "ADDRESS";
        }else if (this.props.lang === "nd"){
            consultas = "ALGEMENE VRAGEN";
            tel = "TELEFOON EN FAX";
            dir = "ADRES";
        }
        return (
            <div className="divoficina">
                <div className="row ">
                    <div className="col">
                        <div className="row ">
                            <div className="col-2" style={{textAlign: "center"}}>
                            <img  className="flechaoficna" src={flecha}></img>
                            
                            </div>
                            <div className="col-10" >
        <p className="oficina"> {this.props.titulo}</p>
                            
                            </div>
                        </div>
                        <div className="row ">
                            <p className="posicion">
                            {this.props.pos}
                   </p>
                        </div>
                    </div>

                </div>
                <div className="row  ">
                    <div className="col">
                        <div className="row  ">
                            <p className="subcontact">
                              {consultas}
                   </p> 
                        </div>
                        <div>
                            <p className="textocontact correocontact">
                            {this.props.correo}
                   </p>
                        </div>
                    </div>


                </div>
                <div className="row  ">
                    <div className="col">
                        <div className="row  ">
                            <p className="subcontact">
                               {tel}
                   </p>
                        </div>
                        <div>
                            <p className="textocontact">
                            {this.props.tel}
                   </p>
                        </div>
                    </div>


                </div>
                <div className="row  ">
                    <div className="col">
                        <div className="row  ">
                            <p className="subcontact">
                            {dir}
                   </p>
                        </div>
                        <div>
                            <p className="textocontact">
                            {this.props.dir}

                   </p>
                        </div>
                    </div>

 
                </div>
               
            </div>
        );
    }
}

export default Oficina;