import React, { Component } from 'react';
import Header from './Header.js';
import Categoriaexp from './Categoriaexp.js';
import Footer from './Footer.js';
import Menu from './Menu.js';

import '../css/productos.css';
import $ from 'jquery';

class Productos extends Component {
    state = {
        categoriasen: ["MINERALS",
            "SALTS",
            "ORGANICS",
            "COLOMBIAN BRANDS"

        ]
        ,
        categoriases: ["MINERALES",
            "SALES",
            "ORGÁNICOS",
            "MARCAS COLOMBIANAS"

        ],
        categoriasnd: ["ND MINERALES",
        "ND SALES",
        "ND ORGÁNICOS",
        "ND MARCAS COLOMBIANAS"

    ],
        paises: [
            ["CALDAS", "MARMATO", "RÍO SUCIO", "TOLIMA"],
            ["CALDAS", "MARMATO", "RÍO SUCIO", "TOLIMA"],
            ["CUNDINAMARCA", "TOLIMA", "ANTIOQUIA", "VALLE DEL CAUCA", "SANTANDER", "CHOCÓ"],
            ["ANTIOQUIA", "CUNDINAMARCA", "SANTANDER"]

        ],
        menu: false,
        lang: this.props.location.lang? this.props.location.lang: "es"



    }
    handleLang = (l) => {
        this.setState({ lang: l });

    }

    handlerMenu = () => {
        this.setState((prevState) => {
            return { menu: !prevState.menu };
        });
    };
    componentDidMount() {
        $(function () {
            $('[data-toggle="tooltip"]').tooltip()
        })
    }
    render() {
        let cats = this.state.categoriases;

        if (this.state.lang === "es") {

        } else if(this.state.lang ==="en"){
            cats = this.state.categoriasen;


        }else if(this.state.lang ==="nd"){
            cats = this.state.categoriasnd;


        }
        return (
            <div>
                <div id="divproductosexp" style={{ height: "100vh" }}>
                    <Menu show={this.state.menu} click={this.handlerMenu} handleLang={this.handleLang}></Menu>
                    <Header click={this.handlerMenu} lang={this.state.lang} handleLang={this.handleLang}></Header>
                    {cats.map((c, index) =>
                        <Categoriaexp key={c.toString()} exp={"true"} titulo={c} paises={this.state.paises[index]} lang={this.state.lang} nombre={this.state.categoriases[index]} />)}


                    <Footer lang={this.state.lang}  ></Footer>
                </div>

            </div>

        )
    }
}

export default Productos;