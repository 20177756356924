import React, { Component } from 'react';
import logoheader from '../images/logotipo NP negro.png';
import $ from 'jquery';
import FlechaHeader from './FlechaHeader.js';
import cruzheader from '../images/Sum rojo.png';
import en from '../lang/en';
import es from '../lang/es';
import nd from '../lang/nd';
import counterpart from 'counterpart';
counterpart.registerTranslations('en', en);
counterpart.registerTranslations('es', es);
counterpart.registerTranslations('nd', nd);
class Header extends Component {

    constructor(props) {
        super(props);
        this.state = { num: "0" };

    }
    clickHandler = (num) => {
        this.setState({ num })
    }
    changeLang = (l) => {

        this.props.handleLang(l);
        counterpart.setLocale(l);
        console.log(l);


    }

    componentDidUpdate() {
       


    }
    componentDidMount() {
        $(function () {
            $('[data-toggle="tooltip"]').tooltip()
        })
    }
    render() {
        let toolnd = "<div> Próximamente!</div>";
        let stylees = {
            fontWeight: "600",

        };
        let styleen = {
            fontWeight: "400"
        }
        let stylend = {
            fontWeight: "400"
        }
        if (this.props.lang == "en") {
            stylees = {
                fontWeight: "400",

            };
            stylend = {
                fontWeight: "400",

            };
            styleen = {
                fontWeight: "600"
            }
        }
        if (this.props.lang == "nd") {
            stylees = {
                fontWeight: "400",

            };
            styleen = {
                fontWeight: "400"
            };
            stylend = {
                fontWeight: "600"
            }
        }
        let nosotros = "Nosotros";
        let portafolio = "Portafolio";
        let alianza = "Alianzas Estratégicas";
        let contact = "Contáctanos";
        if(this.props.lang === "en"){
            nosotros = "About Us";
        }else if(this.props.lang === "nd"){
            nosotros = "Wij";
        }
        
        if(this.props.lang === "en"){
            portafolio = "Portfolio";
        }else if(this.props.lang === "nd"){
            portafolio = "Portefeuille";
        }

        if(this.props.lang === "en"){
            alianza = "Strategic Partnerships";
        }else if(this.props.lang === "nd"){
            alianza = "Strategische verbonden";
        }

        if(this.props.lang === "en"){
            contact = "Contact Us";
        }else if(this.props.lang === "nd"){
            contact = "Neem contact op";
        }
        return (

            <div id="divheader">
                <div id="headermovil">
                    <div className="row bot">
                        <div className="col">
                            <a href="/">
                                <img id="logoheadermovil" src={logoheader}></img>
                            </a>
                        </div>
                        <div className="col" style={{ textAlign: "right" }}>

                            <img id="cruzheadermovil" src={cruzheader} onClick={this.props.click}></img>

                        </div>
                    </div>


                </div>
                <div className="row bot" id="header" style={{ position: this.props.position }}>
                    <div className="col">
                        <div className="row bot" style={{ fontSize: '25px' }}>
                            <div className="col-1">

                                <a href="/">
                                    <img id="logoheader" src={logoheader}></img>
                                </a>


                            </div>
                            <div className="col-2 secciones" onClick={() => this.clickHandler("1")}>
                                <FlechaHeader href="#divabout" texto={nosotros} num="1" numact={this.state.num} ></FlechaHeader>


                            </div>
                            <div className="col-lg-2 col-md-1 secciones" onClick={() => this.clickHandler("2")}>
                                <FlechaHeader href="#divportafolio" texto={portafolio} num="2" numact={this.state.num} ></FlechaHeader>

                            </div>
                            <div className="col-lg-3 col-md-3 secciones" onClick={() => this.clickHandler("3")}>
                                <FlechaHeader href="#divpartners" texto={alianza} num="3" numact={this.state.num} ></FlechaHeader>

                            </div>
                            <div className="col-2 secciones" onClick={() => this.clickHandler("4")}>
                                <FlechaHeader href="#divcontact" texto={contact} num="4" numact={this.state.num} ></FlechaHeader>

                            </div>

                            <div className="col-2 dividiomas" style={{ textAlign: 'right' }}>
                                <button className="idiomasheader " style={styleen} onClick={() => this.changeLang("en")}>
                                    EN  |
                                </button>
                                <button className="idiomasheader " style={stylees} onClick={() => this.changeLang("es")}>
                                    ES  |
                                </button>
                                <button className="idiomasheader " style={stylend} onClick={() => this.changeLang("nd")} >
                                    ND
                                </button>
                                <div>

                                </div>
                            </div>

                        </div>

                    </div>


                </div>
            </div>
        );
    }
}

export default Header;