import React, { Component } from 'react';
import flechablanca from '../images/Flecha historia 700 NP@3x.png';
import Translate from 'react-translate-component';
import Botonhistory from './Botonhistory.js';
import flecharoja from '../images/Flecha quebrada roja.png';

class History extends Component {

    render() {
        let beyond = "Beyond";
        if (this.props.lang == "es") {
            beyond = "Más Allá";
        }else if (this.props.lang == "nd") {
            beyond = "Voorbij";
        }
        return (
            <div className="seccionhistory">
                <Translate content="abouthead4" component="h2" className="tituloseccion"></Translate>

                <div className="row bot divlinea">
                    <div className="divpar">
                        <h1 className="headerhistory"> 2015 </h1>
                        <Botonhistory datatarget="#collapseExample" aria="collapseExample"></Botonhistory>
                    </div>

                    <div className="divpar">
                        <h1 className="headerhistory"> 2017 </h1>
                        
                        <Botonhistory datatarget="#collapseExample2" aria="collapseExample2"></Botonhistory>

                    </div>
                    <div className="divpar">
                        <h1 className="headerhistory"> 2019 </h1>
                        <Botonhistory datatarget="#collapseExample3" aria="collapseExample3"></Botonhistory>

                    </div>
                    <div className="divpar">
                        <h1 className="headerhistory"> {beyond} </h1>
                    </div>
                </div>
                <img className="flechablanca" src={flechablanca}></img>
                <div className="collapse row bot collapsehistory" id="collapseExample">
                    <div className="col-3">
                        <img className="flecharojahistory" src={flecharoja}></img>

                    </div>
                    <div className="col-9"><Translate content="history1" component="p" className="phistory"></Translate>
                    </div>

                </div>
                <div className="collapse row bot collapsehistory2" id="collapseExample2">
                    <div className="col-3">
                        <img className="flecharojahistory" src={flecharoja}></img>

                    </div>
                    <div className="col-9"><Translate content="history2" component="p" className="phistory"></Translate>
                    </div>
                </div>
                <div className="collapse row bot collapsehistory3" id="collapseExample3">
                    <div className="col-3">
                        <img className="flecharojahistory" src={flecharoja}></img>

                    </div>
                    <div className="col-9"><Translate content="history3" component="p" className="phistory"></Translate>
                    </div>
                </div>
            </div>
        );
    }
}

export default History;