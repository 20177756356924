import React, { Component } from 'react';
import telefono from '../images/Phone.png';
import telefonorojo from '../images/teléfono rojo.png';
import linkedrojo from '../images/in rojo.png';
import mailrojo from '../images/mail rojo.png';
import correo from '../images/correo.png';
import linkedin from '../images/in.png';
import $ from 'jquery';
class Footer extends Component {
    componentDidMount() {
        var sourceSwap = function () {
            var $this = $(this);
            var newSource = $this.data('alt-src');
            $this.data('alt-src', $this.attr('src'));
            $this.attr('src', newSource);
        }
        $(function () {
            $('.rojos img').hover(sourceSwap, sourceSwap);
        });
    }

    componentDidUpdate() {
        if (this.props.lang == 'es') {
            let tool1 = document.getElementById("toolwork");
            $(tool1).tooltip().attr('title', "<div> <p> Únete a nuestro equipo! Buscamos profesionales talentosos y proactivos que quieran unirse a nuestro equipo</p> <p>info@naplesprimeci.com</p><p> Gracias por su interés, nos comunicaremos con usted.</p></div>");
            $(tool1).tooltip().attr('data-original-title', "<div> <p> Únete a nuestro equipo! Buscamos profesionales talentosos y proactivos que quieran unirse a nuestro equipo</p> <p>info@naplesprimeci.com</p><p> Gracias por su interés, nos comunicaremos con usted.</p></div>");
            let tool2 = document.getElementById("toolcountry");
            $(tool2).tooltip().attr('data-original-title', "<div> <p> Próximamente!</p></div>");



        }
        if (this.props.lang == 'en') {
            let tool1 = document.getElementById("toolwork");
            $(tool1).tooltip().attr('title', "<div> <p> Join our team! We are looking for talented and proactive professionals who want to join our team</p> <p>info@naplesprimeci.com</p><p> Thank you for your interest, we'll get back to you</p></div>");
            $(tool1).tooltip().attr('data-original-title', "<div> <p> Join our team! We are looking for talented and proactive professionals who want to join our team</p> <p>info@naplesprimeci.com</p><p> Thank you for your interest, we'll get back to you</p></div>");
            let tool2 = document.getElementById("toolcountry");
            $(tool2).tooltip().attr('data-original-title', "<div> <p> Comming soon!</p></div>");


        }
        if (this.props.lang == 'nd') {
            let tool1 = document.getElementById("toolwork");
            $(tool1).tooltip().attr('title', "<div> <p> Word lid van ons team! Wij zijn op zoek naar getalenteerde en proactieve professionals die ons team willen komen versterken</p> <p>info@naplesprimeci.com</p><p> Bedankt voor uw interesse, we nemen contact met u op  </p></div>");
            $(tool1).tooltip().attr('data-original-title',"<div> <p> Word lid van ons team! Wij zijn op zoek naar getalenteerde en proactieve professionals die ons team willen komen versterken</p> <p>info@naplesprimeci.com</p><p> Bedankt voor uw interesse, we nemen contact met u op  </p></div>");
            let tool2 = document.getElementById("toolcountry");
            $(tool2).tooltip().attr('data-original-title', '<div> Binnenkort beschikbaar!</div>');


        }


    }

    render() {
        let trabaja = "Work with us" ;
        let selector = "What´s new? ";
        let toolcountry = '<div> Coming soon!</div>';
        let tool = "<div> <p> Join our team! We are looking for talented and proactive professionals who want to join our team</p> <p>info@naplesprimeci.com</p><p> Thank you for your interest, we'll get back to you</p></div>";
        if (this.props.lang == 'es') {
            trabaja = "Trabajemos";
            selector = "¡Lo nuevo!";
            toolcountry = '<div>Próximamente!</div>';
            tool = "<div> <p> Únete a nuestro equipo! Buscamos profesionales talentosos y proactivos que quieran unirse a nuestro equipo</p> <p>info@naplesprimeci.com</p><p> Gracias por su interés, nos comunicaremos con usted.</p></div>";
        } else if (this.props.lang == 'nd') {
            trabaja = "Werk met ons samens";
            selector = "De nieuwe!";
            toolcountry = '<div> Binnenkort beschikbaar!</div>';
            tool = "<div> <p> Word lid van ons team! Wij zijn op zoek naar getalenteerde en proactieve professionals die ons team willen komen versterken</p> <p>info@naplesprimeci.com</p><p> Bedankt voor uw interesse, we nemen contact met u op  </p></div>";
        }
        return (
            <div className="footer row" id="footerhome" style={{ bottom: "0" }}>
                <div className="col">
                    <div className="row">
                        <div className="col">

                            <p className="textofooter" id="toolwork" data-toggle="tooltip" data-html="true" title={tool}>
                                {trabaja}
                            </p>
                        </div>

                    
                        <div className="col">
                            <p className="textofooter" id="toolcountry" data-toggle="tooltip" data-html="true" title={toolcountry}>
                                {selector}
                            </p>

                        </div>
                    </div>

                </div>
                <div className="col" style={{ textAlign: "center", fontWeight: "500" }}>
                    NAPLES PRIME S.A.S. C.I.
                    </div>
                <div className="col" style={{ textAlign: "right" }}>
                    <div className="row" style={{ textAlign: 'right' }}>
                        <div className="col-8" style={{ textAlign: 'right' }}>

                        </div>
                        <div className="col-1">
                            <a className="rojos" href="tel:+57(1)3904099">
                                <img className="logosfooter" id="telfooter" data-alt-src={telefonorojo} src={telefono}></img>

                            </a>
                        </div>
                        <div className="col-1">
                            <a className="rojos" href="mailto:info@naplesprimeci.com">
                                <img className="logosfooter" id="mailfooter" data-alt-src={mailrojo} src={correo}></img>
                            </a>

                        </div>
                        <div className="col-1">
                            <a className="rojos" href="https://www.linkedin.com/company/naples-prime-sas" target="_blank">
                                <img className="logosfooter" id="telfooter" data-alt-src={linkedrojo} src={linkedin}></img>
                            </a>

                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Footer;
