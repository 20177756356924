import React, { Component } from 'react';

class Botonhistory extends Component {
    state={
        clicked: false
    }
    click = () =>{
       
        this.setState(prevState => ({
            clicked: !prevState.clicked
        }));
    }
    render() {
        let estilo = {backgroundColor:  "transparent"};
        if(this.state.clicked){
            estilo = {backgroundColor:  "whitesmoke"};
        }
        return (
            <div>
             <button className="botonhistory" onClick={this.click} type="button" data-toggle="collapse" data-target={this.props.datatarget} aria-expanded="false" aria-controls={this.props.aria} style={estilo}></button>

            </div>
        );
    }
}

export default Botonhistory;